import Vue from "vue";

// eslint-disable-next-line
export function longDate(dateStr) {
  return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(new Date(dateStr));
}

export function dateFormat(dateStr) {
  const dateAdd = new Date(dateStr);
  return new Intl.DateTimeFormat("pt-BR").format(dateAdd.setHours(dateAdd.getHours() + 3)); // Gambiarra rápida, pois estava retornando errado por causa do timezone!
}

export function dateFormatParam(dateStr, format) {
  switch (format) {
    case "y-m-d":
      return dateStr.split("/").reverse().join("-");
    case "d/m/y":
      return dateStr.split("-").reverse().join("/");
    default:
      return dateStr.split("-").reverse().join("/");
  }
}

export function formatarDataETempo(data) {
  // return new Intl.DateTimeFormat("pt-BR").format(new Date(data));
  // data = data.substr(0, 10);
  return new Intl.DateTimeFormat("pt-BR", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(data));
}

function formatHour(hourStr) {
  // '00:00:00' => '00:00'
  if (hourStr === null) return "- - -";
  const hourSplit = hourStr.split(":");
  const hours = hourSplit[0];
  const minutes = hourSplit[1];
  return `${hours}:${minutes}`;
}

Vue.filter("longDate", longDate);
Vue.filter("dateFormat", dateFormat);
Vue.filter("hour", formatHour);
Vue.filter("formatarData", formatarDataETempo);
Vue.filter("dateFormatParam", dateFormatParam);
