<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Gestão de Mensalidades</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-if="matriculas"
            :items="matriculas"
            :loading="loadingMatriculas"
            :item-text="
              (matricula) =>
                `${matricula?.aluno?.codigo} - ${matricula?.aluno?.nomecompleto} - ${matricula?.turma?.descricao}`.toUpperCase()
            "
            :filter="searchQuery"
            label="Qual matrícula você quer selecionar?"
            return-object
            dense
            cache-items
            hide-no-data
            solo
            @change="selecionarMatricula"
          />
        </v-col>
      </v-row>

      <v-row v-if="loadingParcelas">
        <v-col cols="12">
          <v-skeleton-loader loading type="table" />
        </v-col>
      </v-row>

      <v-row v-if="!loadingParcelas">
        <v-col cols="12">
          <v-col class="d-flex justify-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!matriculaSelecionada.id || !user.caixa"
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => getCarneDePagamento(matriculaSelecionada.id, 'boletos')"
                  class="mr-3"
                >
                  <v-icon small left> fa fa-money </v-icon>
                  Boletos
                </v-btn>
                <v-btn
                  :disabled="!matriculaSelecionada.id || !user.caixa"
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => getCarneDePagamento(matriculaSelecionada.id, 'carne')"
                >
                  <v-icon small left> fa fa-money </v-icon>
                  Carnê
                </v-btn>
              </template>
              <span>Gerar Carnê</span>
            </v-tooltip>
            <div class="ma-2"></div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!matriculaSelecionada.id || !user.caixa"
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    () => [openModalNovaParcela(matriculaSelecionada), (showNovaParcela = true)]
                  "
                >
                  <v-icon small left> fa fa-money </v-icon>
                  Nova Parcela
                </v-btn>
              </template>
              <span>Nova Parcela</span>
            </v-tooltip>
          </v-col>

          <e-snippet-matricula :matricula="matriculaSelecionada" />

          <v-data-table
            :headers="table.headers"
            :items="parcelas ? parcelas : []"
            :items-per-page="6"
          >
            <template v-slot:item.checkbox="{ item: parcela }">
              <v-checkbox
                v-model="boletosSelecionados"
                v-if="!parcela.boleto && parcela.situacao < 1"
                :value="parcela"
                class="ms-3 mt-0"
                hide-details
                @click="showHistorico = false"
              ></v-checkbox>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="parcela.linhaDigitavel"
                    x-small
                    color="green"
                    class="white--text"
                    style="border-radius: 100px; padding-left: 20px; width: 10%"
                    v-bind="attrs"
                    v-on="on"
                    @click="consultarBoleto(parcela)"
                  >
                    <v-icon small left>fa-building</v-icon>
                  </v-btn>
                </template>
                <span>Baixar Parcela</span>
              </v-tooltip>
            </template>

            <template v-slot:item.referencia="{ item: parcela }">
              {{ parcela.referencia | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.valor="{ item: parcela }">
              {{ parcela.valor | currency }}
            </template>

            <template v-slot:item.valorpago="{ item: parcela }">
              <v-chip small :color="parcela.valorPago > 0 ? 'green' : 'red'" class="white--text">
                {{ parcela.valorpago | currency }}
              </v-chip>
            </template>

            <template v-slot:item.valordesconto="{ item: parcela }">
              {{ parcela.desconto | currency }}
            </template>

            <template v-slot:item.valoraberto="{ item: parcela }">
              <v-chip color="green" class="white--text">
                {{ (parcela.valor - parcela.valorpago - parcela.desconto) | currency }}
              </v-chip>
            </template>

            <template v-slot:item.situacao="{ item: parcela }">
              <e-situacao-parcela small :parcela="parcela" />
            </template>

            <template v-slot:item.actions="{ item: parcela }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="parcela.estaPaga || parcela.estaNegociada || !user.caixa"
                    x-small
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openModalBaixarParcela(parcela, matriculaSelecionada)"
                  >
                    <v-icon small> fa fa-download </v-icon>
                  </v-btn>
                </template>
                <span>Baixar parcela</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="primary ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="() => [historico(parcela.id), (showHistorico = true)]"
                  >
                    <v-icon small> mdi-list-status </v-icon>
                  </v-btn>
                </template>
                <span>Histórico</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="parcela.mensalidades.length > 0 ? true : false"
                    x-small
                    color="red ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="() => openModalDeletar(parcela)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Deletar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-sheet>
            <v-btn
              color="primary"
              :disabled="boletosSelecionados.length < 1"
              class="ms-3 mb-3"
              @click="registrarBoletosSelecionados()"
            >
              Registrar boletos selecionados
            </v-btn>
          </v-sheet>
        </v-col>

        <v-dialog v-model="showDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="text-h5"> Caixa fechado </v-card-title>
            <v-card-text>Por favor abra o caixa antes de continuar.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="[(showDialog = false), abrirCaixa()]">
                Abrir Caixa
              </v-btn>
              <v-btn color="green darken-1" text @click="[(showDialog = false)]"> Continuar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modalDeletarMensalidade" max-width="450">
          <v-card>
            <v-card-title class="text-h5"> Deseja excluir essa mensalidade? </v-card-title>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="modalDeletarMensalidade = false">
                Continuar
              </v-btn>
              <v-btn color="green darken-1" text @click="modalDeletarMensalidade = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <NovaParcela
          @matriculasVinculadas="refresh()"
          @status="handleStatus"
          :showNovaParcela="showNovaParcela"
          :matricula="matriculaSelecionada.id"
        />
      </v-row>
    </v-container>

    <Drawer
      :show="showHistorico"
      :parcela="parcela"
      :listaDeParcelas="listaDeParcelas"
      @valorEstornado="loadAgain()"
    />
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";
import searchQuery from "@/plugins/searchQuery";
import Drawer from "@/components/Mensalidades/Drawer.vue";
import NovaParcela from "@/components/Mensalidades/NovaParcela.vue";

export default {
  name: "MensalidadesPage",
  components: {
    Drawer,
    NovaParcela,
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      modalDeletarMensalidade: false,
      estornoMessage: false,
      showDialog: false,
      showNovaParcela: false,
      listaDeParcelas: [],
      parcela: {},
      showHistorico: false,
      matriculaSelecionada: {},
      matriculas: [],
      parcelas: [],
      parcelaSelecionada: {},
      boletosSelecionados: [],
      searchQuery,
      loadingMatriculas: true,
      loadingParcelas: false,
      modalBaixarParcela: { open: false, parcela: null },
      table: {
        headers: [
          { text: "Boleto", value: "checkbox" },
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
          { text: "Valor Pago", value: "valorpago" },
          { text: "Valor Desconto", value: "valordesconto" },
          { text: "Valor Aberto", value: "valoraberto" },
          { text: "Situação", value: "situacao", align: "center" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.loadMatriculas();
    if (!this.user.caixa) {
      this.showDialog = true;
    }
  },

  methods: {
    openModalDeletar(parcela) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja excluir esta parcela?",
        confirmationCode: parcela.id,
        confirmationMessage: `Por favor, digite <strong>${parcela.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Parcela");
              try {
                await this.$services.parcelasService.deletarParcela(parcela.id);
                this.$toast.success("Parcela excluida com sucesso");
                this.$forceUpdate();
                this.refresh();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    consultarBoleto(parcela) {
      this.$loaderService.open("Consultanto Boleto");
      // Implementar a pesquisa ao boleto
      this.$services.parcelasService
        .consultarBoleto(parcela)
        .then((response) => {})
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => {
          this.$loaderService.close();
        });
    },

    async registrarBoletosSelecionados() {
      this.boletosSelecionados.forEach((boleto, index) => {
        this.$loaderService.open("Aguarde o sistema está registrando o(s) Boleto(s)");
        this.$services.parcelasService
          .incluirBoleto(boleto)
          .then(async (response) => {
            this.boletosSelecionados = [];
            this.$toast.success("Boleto registrado com sucesso!");
            this.refresh();
          })
          .catch((err) => {
            this.boletosSelecionados = [];
            this.$toast.warn(err.message);
            this.refresh();
          });
      });
    },

    handleStatus(event) {
      this.showNovaParcela = event;
    },
    selecionarMatricula(matricula) {
      this.showNovaParcela = false;
      this.showHistorico = false;

      this.matriculaSelecionada = { ...matricula };
      if (matricula !== this.matriculaSelecionada) {
        this.loadParcelas(matricula);
      }
    },

    async getCarneDePagamento(matricula_id, type) {
      this.showHistorico = false;
      this.loadingParcelas = true;
      try {
        const response = await this.$services.matriculasService.getMatricula(matricula_id, type);
      } catch (exception) {
        this.$handleError(exception);
      } finally {
        this.loadingParcelas = false;
      }
    },

    async loadParcelas(matricula) {
      this.boletosSelecionados = [];
      this.loadingParcelas = true;
      this.matriculaSelecionada = {};
      try {
        const matriculaDetalhada = await this.$services.matriculasService.getDetails(matricula.id);
        this.$loaderService.close(); // NÃO REMOVA ISSO, ESTÁ SENDO CHAMADO EM OUTRA FUNÇÃO AO REGISTRAR OS BOLETOS. ISSO MESMO É GAMBIARRA.
        this.parcelas = await this.$services.matriculasService.parcelas(
          matriculaDetalhada.parcelas
        );
        this.matriculaSelecionada = { ...matriculaDetalhada };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingParcelas = false;
    },

    async loadMatriculas() {
      this.loadingMatriculas = true;
      try {
        const data = await this.$services.matriculasService.all();
        this.matriculas = data;
      } catch (error) {
        this.$handleError(error.message || error.response.data.error.message);
      }
      this.loadingMatriculas = false;
    },

    async historico(parcela) {
      this.showNovaParcela = false;
      const response = await this.$services.parcelasService.getHistorico(parcela);
      this.parcela = { ...response.data };
      this.listaDeParcelas = [...response.data.mensalidades];
    },

    openModalBaixarParcela(parcela, matriculaSelecionada) {
      this.parcela = { ...parcela };
      this.$router.push({
        name: "mensalidades.baixar",
        params: { prestacao_id: this.parcela.id, matricula_id: matriculaSelecionada.id },
      });
    },

    openModalNovaParcela() {
      this.showHistorico = false;
      this.showNovaParcela = !this.showNovaParcela;
    },

    refresh() {
      this.estornoMessage = true;
      this.showNovaParcela = false;
      this.showHistorico = false;
      this.loadParcelas(this.matriculaSelecionada);
      this.boletosSelecionados = [];
    },

    loadAgain() {
      this.estornoMessage = true;
      this.showNovaParcela = false;
      this.showHistorico = false;
      this.loadParcelas(this.matriculaSelecionada);
      this.$toast.success("Baixa feita com sucesso!");
    },

    async verificarCaixaAberto() {
      if (!this.user.caixa) {
        this.showDialog = true;
      }
    },

    abrirCaixa() {
      this.$router.push({ name: "caixas" });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   if (!from || from.name !== "mensalidades.baixar") {

  //     store.commit("Mensalidades/setMatriculaSelecionada", null);
  //     store.commit("Mensalidades/setParcelaSelecionada", null);
  //   }
  //   next();
  // },
};
</script>
<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>
