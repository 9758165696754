<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          <span> {{ circuito.descricao }} </span>
          / Novo Ciclo
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-4">
          <template>
            <v-form>
              <v-text-field
                v-model="form.descricao"
                label="Bimestre 1..."
                :rules="[(v) => !!v || 'Campo obrigatório']"
                required
              >
              </v-text-field>

              <v-row>
                <v-col>
                  <v-btn small color="success" @click="salvarCiclo"> Adicionar </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="mt-8 mb-5"></v-divider>

    <v-row>
      <v-col>
        <span class="text-h5">Ciclos</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Descricão</th>
                <th class="text-left">Situação</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in ciclos" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td>{{ item.situacao ? "Desativado" : "Ativado" }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        @click="() => adicionarCampos(item.id)"
                        x-small
                        dark
                        class="ml-1"
                        color="blue lighten-1"
                        v-bind="attrs"
                      >
                        <v-icon small>mdi-link</v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar campos</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        @click="() => editarCiclo(item)"
                        x-small
                        class="ml-1"
                        color="info lighten-1"
                        v-bind="attrs"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar ciclo</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => deletarCiclo(item)"
                        x-small
                        class="ml-1"
                        color="error lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar ciclo</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <span class="text-h5">Teste de lançamento de notas</span>
      </v-col>
    </v-row>

    <template>
      <v-card>
        <v-tabs
          show-arrows
          background-color="primary accent-4"
          center-active
          dark
          v-model="tab_atual"
          :id="tab_atual"
        >
          <v-tab v-for="tab in tabs" :key="tab.id">
            {{ tab.descricao }}
          </v-tab>
        </v-tabs>
      </v-card>
    </template>

    <template v-if="avaliacoes">
      <v-row v-for="(avaliacao, indexAvaliacao) in avaliacoes ?? []" :key="indexAvaliacao">
        <!-- <div class="mt-5">
          {{ avaliacao.aluno.nomecompleto }}
        </div> -->

        <template v-if="avaliacao.notas[tab_atual]">
          <v-col
            class="mt-5"
            v-for="(nota, index) in avaliacao.notas[tab_atual].campos"
            :key="index"
          >
            {{ nota.nomecampo }}

            <v-currency-field
              v-if="!nota.label"
              @input="
                (e) =>
                  resposta(
                    avaliacao.notas[tab_atual].regras,
                    avaliacao.notas[tab_atual].campos,
                    indexAvaliacao,
                    e,
                    nota
                  )
              "
              v-model="nota.nota"
              :default-value="0"
              hide-details
              :allow-negative="false"
              :decimal-length="nota.decimal ? 2 : 1"
              ref="input"
              v-bind="{ outlined: true }"
              :disabled="nota.editavel"
            />

            <v-text-field
              :id="nota.id"
              v-model="nota.nota"
              filled
              v-if="nota.label"
              :background-color="nota.color ?? nota.color"
              :disabled="nota.editavel"
            >
              {{ nota.nota }}
            </v-text-field>
          </v-col>
        </template>
      </v-row>
    </template>
  </main-template>

  <!-- <v-text-field
          v-if="nota.label"
          filled
          rounded
          dense
          @input="
            resposta(
              avaliacao.notas[tab_atual].regras,
              avaliacao.notas[tab_atual].campos,
              indexAvaliacao
            )
          "
          v-model="nota.nota"
          :type="nota.tipo"
          :disabled="nota.editavel"
          :max="nota.nota_maxima"
          :min="nota.nota_minima"
        >
        </v-text-field> -->
</template>

<script>
export default {
  data() {
    return {
      campoTemporario: 0,
      avaliacoes: [
        {
          id: 1,
          aluno: {
            nomecompleto: "Aluno Teste",
          },
          notas: [],
          campos: [],
        },
      ],
      tab_atual: 0,
      form: {
        circuito_id: this.$route.params.id,
        descricao: "",
      },
      circuito: {},
      ciclos: [],
      campos: [],

      respostas: [],
      tabs: [],
      notas: [],
      nota: 0,
    };
  },

  mounted() {
    this.form.circuito_id = this.$route.params.id;
    this.getCiclo(this.$route.params.id);
    this.listarCiclos(this.$route.params.id);
  },

  methods: {
    resposta(regras, campos, indexAvaliacao, e, nota) {
      if (nota.nota > nota.notamaxima) {
        nota.nota = nota.notamaxima;
        e = nota.notamaxima;
      }
      // nota.nota = nota.nota > parseInt(nota.notamaxima) ? nota.notamaxima : nota.nota;

      let expressao = "";
      regras.map((regra) => {
        if (
          regra.tipo === "conjunto" ||
          regra.tipo === "campo" ||
          regra.tipo === "numero" ||
          regra.tipo === "expressao"
        ) {
          expressao += regra.elem;
        }
      });

      regras.map((regra) => {
        if (regra.tipo === "campo") {
          campos.map((campo) => {
            if (campo.slug === regra.elem) {
              expressao = expressao.toString();
              expressao = expressao.replaceAll(regra.elem, campo.nota ?? 0);
            }
          });
        }

        if (regra.tipo === "resultado") {
          campos.map((campo) => {
            if (campo.slug === regra.elem) {
              campo.nota = eval(expressao).toFixed(2) ?? 0;
            }
          });
        }

        if (regra.tipo === "resposta") {
          campos.map((campo) => {
            if (campo.slug === regra.elem) {
              campos.map((c) => {
                if (c.slug === regra.campo_condicao) {
                  if (eval(regra.condicao.toString().replaceAll(c.slug, c.nota))) {
                    campo.color = regra.color ?? regra.color;
                    campo.nota = regra.resposta;
                  }
                }
              });
            }
          });
        }

        if (regra.tipo === "resultado bimestral") {
          let expressao = "";

          regra.regras.map((r) => {
            expressao += r.elem;
          });

          regra.regras.map((r) => {
            if (r.tipo === "campo") {
              this.avaliacoes[indexAvaliacao].notas.map((avali) => {
                if (avali.slug === r.bimestre) {
                  avali.campos.map((a) => {
                    if (a.slug === r.elem) {
                      expressao = expressao.toString().replace(a.slug, a.nota ?? 0);
                    }
                  });
                }
              });
            }
          });

          this.avaliacoes[indexAvaliacao].notas.map((n) => {
            if (n.slug === regra.elem) {
              n.campos.map((nota) => {
                if (nota.slug === regra.campo) {
                  const novaNota = eval(expressao) ?? 0;
                  nota.nota = novaNota.toFixed(2);
                }
              });
            }
          });
        }

        if (regra.tipo === "Habilitar/desabilitar campo") {
          const { ciclo_escolhido } = regra;
          const { campo_condicao } = regra;

          let resultado;

          this.avaliacoes[indexAvaliacao].notas.map((n) => {
            if (n.slug === ciclo_escolhido) {
              n.campos.map((c) => {
                const expressao = regra.condicao;
                if (c.slug === campo_condicao) {
                  resultado = expressao.toString().replaceAll(campo_condicao, c.nota);
                }
              });
            }
          });

          const { ciclo_resultado } = regra;
          const { campo_resultado } = regra;

          this.avaliacoes[indexAvaliacao].notas.map((n) => {
            if (n.slug === ciclo_resultado) {
              n.campos.map((c) => {
                if (c.slug === campo_resultado) {
                  if (eval(resultado)) {
                    c.editavel = regra.desabilitado;
                  }

                  if (!eval(resultado)) {
                    c.editavel = !regra.desabilitado;
                    c.nota = 0;
                  }
                }
              });
            }
          });
        }

        if (regra.tipo === "resultado em bimestre") {
          const campoDoCicloQueTemOValor = regra.campo;
          const cicloQueTemOValor = regra.ciclo_escolhido;

          let valor = null;
          this.avaliacoes[indexAvaliacao].notas.map((n) => {
            if (n.slug === cicloQueTemOValor) {
              n.campos.map((c) => {
                if (c.slug === campoDoCicloQueTemOValor) {
                  valor = eval(c.nota);
                }
              });
            }
          });

          const cicloOndeVaiCairOValor = regra.ciclo_resultado;
          const campoOndeVaiCairOValor = regra.campo_resultado;

          this.avaliacoes[indexAvaliacao].notas.map((n) => {
            if (n.slug === cicloOndeVaiCairOValor) {
              n.campos.map((c) => {
                if (c.slug === campoOndeVaiCairOValor) {
                  c.nota = valor;
                }
              });
            }
          });
        }
      });
    },

    async salvarCiclo() {
      await this.$services.cicloService
        .salvar(this.form)
        .then(() => {
          this.$toast.success("Ciclo salvo com sucesso!");
          this.listarCiclos(this.form.circuito_id);
          this.form.descricao = "  ";
        })
        .catch(() => {
          this.$toast.danger("Ocorreu um problema ao salvar o ciclo, tente novamente");
        });
    },

    async listarCiclos(circuito_id) {
      const { data } = await this.$services.cicloService.ciclosDoCircuito(circuito_id);
      this.ciclos = data;
      this.tabs = this.ciclos;
      this.avaliacoes[0].notas = [...data];
    },

    async getCiclo(circuito_id) {
      const data = await this.$services.circuitoService.getCircuito(circuito_id);
      this.circuito = { ...data };
    },

    async editarCiclo(ciclo) {
      this.$router.push({ name: "ciclo.edit", params: { id: ciclo.id } });
    },

    async deletarCiclo(ciclo) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esse ciclo?",
        confirmationCode: ciclo.id,
        confirmationMessage: `Por favor, digite <strong>${ciclo.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Ciclo");
              try {
                await this.$services.cicloService.deletarCiclo(ciclo.id);
                this.listarCiclos(this.form.circuito_id);
                this.$toast.success("Ciclo deletado com sucesso");
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    adicionarCampos(id) {
      this.$router.push({ name: "circuito.ciclo.campos", params: { id } });
    },
  },
};
</script>

<style></style>
