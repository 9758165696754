import Axios from "@/plugins/Axios";
import authService from "@/Services/AuthService";
import store from "@/store";

export class CaixasService {
  async syncAll() {
    const response = await Axios().get("caixas");
    const caixas = response.data;
    store.commit("Caixas/setCaixas", caixas);
    return caixas;
  }

  async update(caixa) {
    const response = await Axios().put(`caixas/${caixa.id}`, caixa);
    return response.data;
  }

  async abrirCaixa(caixa, valorInicial) {
    const response = await Axios().post("caixas/abrir", {
      caixa_id: caixa.id,
      valorInicial,
    });
    const user = store.getters["Auth/user"];

    caixa.estaAberto = true;
    caixa.user = user;

    caixa.animationClass = "animate__animated animate__bounce";
    setTimeout(() => (caixa.animationClass = ""), 1000);

    store.commit("Auth/setUser", { ...user, caixa });

    authService.syncUserWithStore();
    return response.data;
  }

  async fecharCaixa(caixa, valor) {
    const response = await Axios().post("caixas/fechar", {
      caixa_id: caixa.id,
      valor,
    });
    caixa.estaFechado = true;

    caixa.animationClass = "animate__animated animate__bounce";
    setTimeout(() => (caixa.animationClass = ""), 1000);

    const user = store.getters["Auth/user"];
    store.commit("Auth/setUser", { ...user, caixa: null });
    return response.data;
  }

  // async fecharCaixaId(caixa_id, totalCaixa) {
  //   const response = await Axios().post("caixas/fechar", {
  //     caixa_id: caixa_id,
  //     valor: totalCaixa,
  //   });
  //   caixa.estaFechado = true;

  //   caixa.animationClass = "animate__animated animate__bounce";
  //   setTimeout(() => (caixa.animationClass = ""), 1000);

  //   const user = store.getters["Auth/user"];
  //   store.commit("Auth/setUser", { ...user, caixa: null });
  //   return response.data;
  // }

  async listarSituacoes(caixa) {
    const response = await Axios().get(`caixas/${caixa.id}/situacoes`);
    return response.data;
  }

  async listarUsuariosParaCriarCaixa(caixa) {
    const response = await Axios().get(`caixas/listar-usuarios-para-criar-caixa`);
    return response.data;
  }

  // opção para criar para vários usuários
  async criar(data) {
    const response = await Axios().post(`caixas/criar`, { data });
    return response.data;
  }

  // opção para criar único de caixa de forma rápida pelo modal
  async criarMeuCaixa(data) {
    const response = await Axios().post(`caixas/criar-meu-caixa`, data);
    return response.data;
  }
}
export default new CaixasService();
