import CircuitoIndex from "@/Pages/Circuito/index.vue";
import CircuitoPageNew from "@/Pages/Circuito/new.vue";
import CircuitoPageEdit from "@/Pages/Circuito/edit.vue";

import NovoCiclo from "@/Pages/Ciclos/new.vue";
import NovoCampo from "@/Pages/Ciclos/campos.vue";
import CicloEdit from "@/Pages/Ciclos/edit.vue";
import EditCampo from "@/Pages/Ciclos/editCampo.vue";
import NovaRegra from "@/Pages/Ciclos/novaRegra.vue";

export default [
  {
    path: "/circuito/notas",
    name: "circuito",
    component: CircuitoIndex,
  },
  {
    path: "/circuito/notas/novo",
    name: "circuito.novo",
    component: CircuitoPageNew,
  },
  {
    path: "/circuito/ciclo/novo/:id",
    name: "circuito.ciclo.novo",
    component: NovoCiclo,
  },
  {
    path: "/circuito/ciclo/:id/campos",
    name: "circuito.ciclo.campos",
    component: NovoCampo,
  },
  {
    path: "/circuito/:id/edit",
    name: "circuito.edit",
    component: CircuitoPageEdit,
  },
  {
    path: "/ciclo/:id/edit",
    name: "ciclo.edit",
    component: CicloEdit,
  },
  {
    path: "/ciclo/campo/:id/edit",
    name: "ciclo.campos.edit",
    component: EditCampo,
  },
  {
    path: "/ciclo/campo/:id/nova/regra",
    name: "circuito.ciclo.campo.novaRegra",
    component: NovaRegra,
  },
];
