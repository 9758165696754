<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Editar Circuito
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-4">
          <template>
            <template>
              <v-form @submit="handleFormSubmit">
                <v-text-field
                  v-model="form.descricao"
                  label="Ex: circuito ensino fundamental"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  required
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-btn :disabled="!form.descricao" color="success" type="submit">
                      Atualizar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      circuitoId: null,
      form: {},
    };
  },

  mounted() {
    this.circuitoId = this.$route.params.id;
    this.getCircuito(this.circuitoId);
  },

  methods: {
    async handleFormSubmit(e) {
      e.preventDefault();
      this.atualizarCircuito();
    },

    async atualizarCircuito() {
      await this.$services.circuitoService.atualizar(this.form, this.$route.params.id);
      this.$router.go(-1);
      this.$toast.success("Circuito atualizado com sucesso!");
    },

    async getCircuito(circuitoId) {
      const response = await this.$services.circuitoService.getCircuito(circuitoId);
      this.form = { ...response };
    },
  },
};
</script>

<style></style>
