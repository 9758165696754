import Axios from "@/plugins/Axios";

export class CircuitoService {
  async index(pageNumber, query) {
    const response = await Axios().get(`circuitos/all?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async salvar(form) {
    const response = await Axios().post("circuitos/salvar", form);
    return response.data;
  }

  async getCircuito(circuitoId) {
    const response = await Axios().get(`circuitos/${circuitoId}`);
    return response.data;
  }

  async deletarCircuito(circuitoId) {
    const response = await Axios().delete(`circuitos/${circuitoId}`);
    return response.data;
  }

  async atualizar(form, circuitoId) {
    const response = await Axios().put(`circuitos/atualizar/${circuitoId}`, form);
    return response.data;
  }
}

export default new CircuitoService();
