<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          <span>{{ ciclo.descricao }} / Nova regra</span>
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-5">
          <!-- tipo campo -->
          <v-select :items="tipos" v-model="tipo" label="Tipo do campo" />

          <!-- campo da condicao -->
          <v-select
            v-if="tipo === 'resposta'"
            :items="valoresSelecionados"
            v-model="campo_condicao"
            label="Campo da condição"
          />

          <!-- condicao -->
          <v-text-field
            v-if="tipo === 'resposta'"
            v-model="condicao"
            label="Condição"
            required
          ></v-text-field>

          <!-- resposta -->
          <v-text-field
            v-if="tipo === 'resposta'"
            v-model="resposta"
            label="Resposta"
            required
          ></v-text-field>

          <!-- elemento -->
          <v-select
            :items="valoresSelecionados"
            v-if="
              tipo !== 'resultado bimestral' &&
              tipo !== 'resultado em bimestre' &&
              tipo !== 'Habilitar/desabilitar campo'
            "
            v-model="elemento"
            label="Campo resultado"
          />

          <v-color-picker
            hide-canvas
            hide-inputs
            hide-mode-switch
            hide-sliders
            mode="rgba"
            show-swatches
            swatches-max-height="125"
            v-if="tipo === 'resposta'"
            @input="(e) => handleColor(e)"
          ></v-color-picker>

          <v-col v-if="tipo === 'resposta'"></v-col>

          <!-- se for do tipo resultado bimestral -->
          <v-select
            v-if="tipo === 'resultado bimestral'"
            :items="ciclos"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhido"
          >
          </v-select>

          <v-select
            v-if="tipo === 'resultado bimestral'"
            :items="camposDoCicloEscolhido"
            v-model="campoDoCicloEscolhido"
            item-text="slug"
            label="Selecione o campo do ciclo"
          >
          </v-select>

          <!-- habilitar ou desabilitar campo -->
          <v-select
            v-if="tipo === 'Habilitar/desabilitar campo'"
            :items="ciclos"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhido"
          />

          <v-select
            v-if="tipo === 'Habilitar/desabilitar campo'"
            :items="camposDoCicloEscolhido"
            v-model="campo_condicao"
            item-text="slug"
            label="Campo escolhido"
          />

          <v-select
            v-if="tipo === 'Habilitar/desabilitar campo'"
            :items="ciclosResultadoBimestre"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhidoDoResultado"
          >
          </v-select>

          <v-select
            v-if="tipo === 'Habilitar/desabilitar campo'"
            :items="camposDoCicloEscolhidoEmBimestre"
            v-model="campoDoCicloEscolhidoEmBimestre"
            item-text="slug"
            label="Selecione o campo do ciclo"
          >
          </v-select>

          <v-text-field
            v-if="tipo === 'Habilitar/desabilitar campo'"
            v-model="condicao"
            label="Condição"
            required
          ></v-text-field>

          <v-checkbox
            v-if="tipo === 'Habilitar/desabilitar campo'"
            required
            v-model="desabilitado"
            :label="`Desabilitado?`"
          ></v-checkbox>

          <!-- resultado em bimestre -->
          <v-select
            v-if="tipo === 'resultado em bimestre'"
            :items="ciclos"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhido"
          />

          <v-select
            v-if="tipo === 'resultado em bimestre'"
            :items="camposDoCicloEscolhido"
            v-model="campo_condicao"
            item-text="slug"
            label="Campo escolhido"
          />

          <v-select
            v-if="tipo === 'resultado em bimestre'"
            :items="ciclosResultadoBimestre"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhidoDoResultado"
          >
          </v-select>

          <!-- camposDoCicloEscolhidoEmBimestre -->
          <v-select
            v-if="tipo === 'resultado em bimestre'"
            :items="camposDoCicloEscolhidoEmBimestre"
            v-model="campoDoCicloEscolhidoEmBimestre"
            item-text="slug"
            label="Selecione o campo do ciclo"
          >
          </v-select>

          <v-btn dark color="primary" @click="adicionar()"> Adicionar elemento</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        Regra do ciclo <strong>{{ ciclo.descricao }}</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn dark color="green" class="ml-2" @click="salvar()"> Salvar regra</v-btn>
      </v-col>
    </v-row>

    <!-- modal editar regra -->
    <v-dialog v-model="openModalEditarRegra" max-width="300">
      <v-card>
        <v-card-title class="text-h5"> Editar elemento </v-card-title>

        <v-card-text>
          <v-text-field
            v-if="regraEditavel.tipo !== 'campo'"
            v-model="regraEditavel.elem"
            :label="regraEditavel.tipo"
            filled
            required
          ></v-text-field>

          <v-select
            v-if="regraEditavel.tipo === 'campo'"
            :items="campos"
            v-model="regraEditavel.elem"
            filled
            label="Campo resultado"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="green darken-1" text @click="openModalEditarRegra = false">
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="(openModalEditarRegra = false), atualizarRegra()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog para adicionar nova regra -->
    <v-row justify="center">
      <v-dialog v-model="novaRegra" persistent max-width="800">
        <v-card class="px-10 py-10">
          <!-- tipo campo -->
          <v-select :items="tiposModal" v-model="tipo" label="Tipo do campo" />

          <v-select
            v-if="tipo === 'campo'"
            :items="ciclos"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhido"
          >
          </v-select>

          <v-select
            v-if="tipo === 'campo'"
            :items="camposDoCicloEscolhido"
            v-model="campoDoCicloEscolhido"
            item-text="slug"
            label="Selecione o campo do ciclo"
          >
          </v-select>
          <!-- aqui -->

          <!-- campo da condicao -->
          <v-select
            v-if="tipo === 'resposta'"
            :items="valoresSelecionados"
            v-model="campo_condicao"
            label="Campo da condição"
          />
          <!-- elemento -->
          <v-select
            :items="valoresSelecionados"
            v-if="tipo !== 'resultado bimestral' && tipo !== 'campo'"
            v-model="elemento"
            label="Campo"
          />
          <!-- condicao -->
          <v-text-field
            v-if="tipo === 'resposta'"
            v-model="condicao"
            label="Condição"
            required
          ></v-text-field>
          <!-- resposta -->
          <v-text-field
            v-if="tipo === 'resposta'"
            v-model="resposta"
            label="Resposta"
            required
          ></v-text-field>

          <!-- se for do tipo resultado bimestral -->
          <v-select
            v-if="tipo === 'resultado bimestral'"
            :items="ciclos"
            item-text="descricao"
            return-object
            label="Selecione o ciclo abaixo"
            v-model="cicloEscolhido"
          >
          </v-select>

          <v-select
            v-if="tipo === 'resultado bimestral'"
            :items="camposDoCicloEscolhido"
            v-model="campoDoCicloEscolhido"
            item-text="slug"
            label="Selecione o campo do ciclo"
          >
          </v-select>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="novaRegra = false"> Sair </v-btn>
            <v-btn color="green darken-1" text @click="() => salvarRegraNocampo()">
              Adicionar
            </v-btn>
          </v-card-actions>

          <template v-if="campoNovaRegra">
            <h3>Sub-regras</h3>
            <v-row
              class="mb-2 rounded"
              v-for="regra in regras[this.campoNovaRegra].regras"
              :key="regra.id"
            >
              <v-col>
                <v-card class="px-5 py-5 d-flex flex-row justify-start align-center">
                  <v-chip> Tipo: {{ regra.tipo ?? "" }} </v-chip>
                  <v-chip> Campo: {{ regra.elem ?? "" }} </v-chip>
                  <v-chip v-if="regra.bimestre">
                    Bimestre: {{ regra.bimestre ?? regra.bimestre }}
                  </v-chip>
                  <v-btn rounded small dark color="red" class="ml-2"> Remover </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-timeline dense v-if="regras.length > 0">
      <v-timeline-item v-for="(item, i) in regras" :key="i" class="pai" color="secondary">
        <v-card :id="i" class="mb-6">
          <v-row>
            <v-col cols="6">
              <v-card-title class="text-h6">
                <v-chip v-if="item.elem" color="primary lighten-1">
                  Elemento:
                  {{ item.elem ? item.elem : "" }}
                </v-chip>
              </v-card-title>

              <v-card-text class="white text--primary">
                <p v-if="item.tipo">
                  <v-chip>
                    Tipo:
                    {{ item.tipo ? item.tipo : "" }}
                  </v-chip>
                </p>
                <p v-if="item.elem">
                  <v-chip>
                    Valor:
                    {{ item.elem ? item.elem : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.campo_condicao">
                  <v-chip>
                    Campo da condição:
                    {{ item.campo_condicao ? item.campo_condicao : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.condicao">
                  <v-chip>
                    Condição:
                    {{ item.condicao ? item.condicao : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.campo">
                  <v-chip>
                    Campo:
                    {{ item.campo ? item.campo : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.resposta">
                  <v-chip> Resposta: {{ item.resposta ? item.resposta : "- - -" }} </v-chip>
                </p>
                <p v-if="item.ciclo_escolhido">
                  <v-chip>
                    Ciclo escolhido: {{ item.ciclo_escolhido ? item.ciclo_escolhido : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.campo">
                  <v-chip> Campo escolhido: {{ item.campo ? item.campo : "- - -" }} </v-chip>
                </p>
                <p v-if="item.ciclo_resultado">
                  <v-chip>
                    Ciclo resultado: {{ item.ciclo_resultado ? item.ciclo_resultado : "- - -" }}
                  </v-chip>
                </p>
                <p v-if="item.campo_resultado">
                  <v-chip>
                    Campo resultado: {{ item.campo_resultado ? item.campo_resultado : "- - -" }}
                  </v-chip>
                </p>
                <p>
                  <v-chip> Desabilitado: {{ item.desabilitado ? "sim" : "não" }} </v-chip>
                </p>
                <p v-if="item.color">
                  <v-chip> Cor: </v-chip>
                  <v-chip :color="item.color">
                    {{ "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" }}
                  </v-chip>
                </p>
                <p>
                  <v-btn
                    rounded
                    small
                    class="mx-0"
                    dark
                    color="red lighten-1"
                    @click="() => deletarRegra(i + 1)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </p>
                <p>
                  <v-btn
                    v-if="item.tipo === 'resultado bimestral'"
                    rounded
                    small
                    class="mx-0"
                    dark
                    color="purple lighten-1"
                    @click="() => adicionarRegra(i)"
                  >
                    Adicionar sub-regra
                  </v-btn>
                </p>

                <h3 v-if="item.regras" class="mt-5">Sub-regras</h3>
                <v-divider class="my-2"></v-divider>
                <v-row v-for="(regra, regraIndex) in item.regras" :key="regraIndex" class="rounded">
                  <div cols="12" class="d-flex flex-row align-center px-2 py-2 my-2">
                    <v-chip v-if="regra.bimestre">Bimestre: {{ regra.bimestre }}</v-chip>
                    <v-chip> Elemento: {{ regra.elem }} </v-chip>
                    <v-chip> Tipo: {{ regra.tipo }} </v-chip>

                    <v-btn
                      rounded
                      small
                      dark
                      color="red lighten-1"
                      @click="() => deletarSubRegra(i, regraIndex)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-card-text>
            </v-col>

            <v-col cols="6" sm="6" class="py-2 d-flex justify-end">
              <v-btn-toggle class="d-flex flex-column justify-space-between mr-2">
                <v-btn>
                  <v-icon @click="paraCima($event, i)">mdi-upload-outline</v-icon>
                </v-btn>
                <v-btn>
                  <v-icon @click="paraBaixo($event, i)">mdi-download-outline</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </main-template>
</template>

<script>
import { arrayMoveImmutable } from "array-move";

export default {
  mounted() {
    this.carregarCampos();
  },

  data() {
    return {
      novaRegra: false,
      ciclo: {},
      algumaCoisa: null,
      valoresSelecionados: [],
      tipos: [
        "expressao",
        "conjunto",
        "campo",
        "numero",
        "resultado",
        "resposta",
        "resultado em bimestre",
        "resultado bimestral",
        "Habilitar/desabilitar campo",
      ],
      tiposModal: ["expressao", "conjunto", "campo", "numero"],
      expressoes: ["+", "-", "/", "*"],
      conjuntos: ["(", ")"],
      campos: [],
      numeros: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      respostas: ["resultado"],

      elemento: "",
      tipo: "",
      regras: [],

      variavel: "",
      condicao: "",
      entao: "",
      variavelSelecionada: "",

      openModalEditarRegra: false,
      regraEditavel: {},
      indexRegraEditavel: null,

      // Ciclos
      ciclos: [],
      cicloEscolhido: null,
      camposDoCicloEscolhido: [],
      campoDoCicloEscolhido: null,
      tipoCiclo: null,

      campo_condicao: "",
      campoNovaRegra: null,

      //
      campoDoCicloEscolhidoBuscar: null,
      cicloEscolhidoBuscar: null,

      //
      ciclosResultadoBimestre: [],
      cicloEscolhidoDoResultado: null,
      camposDoCicloEscolhidoEmBimestre: [],
      campoDoCicloEscolhidoEmBimestre: null,

      //
      color: null,
      desabilitado: true,
    };
  },

  watch: {
    cicloEscolhido(value) {
      this.carregarCamposDoCicloEscolhido(value.id);
    },

    cicloEscolhidoDoResultado(value) {
      this.carregarCamposDoCicloEscolhidoEmBimestre(value.id);
    },

    tipo(value) {
      if (value === "expressao") {
        this.valoresSelecionados = [...this.expressoes];
      }
      if (value === "conjunto") {
        this.valoresSelecionados = [...this.conjuntos];
      }
      if (value === "campo") {
        this.valoresSelecionados = [...this.campos];
      }
      if (value === "numero") {
        this.valoresSelecionados = [...this.numeros];
      }
      if (value === "resultado") {
        this.valoresSelecionados = [...this.campos];
      }
      if (value === "condicao") {
        this.valoresSelecionados = [...this.campos];
      }
      if (value === "resposta") {
        this.valoresSelecionados = [...this.campos];
      }
      if (value === "resultado bimestral") {
        this.valoresSelecionados = [...this.ciclos];
      }
      if (value === "resultado em bimestre") {
        this.ciclosResultadoBimestre = [...this.ciclos];
      }
      if (value === "Habilitar/desabilitar campo") {
        this.ciclosResultadoBimestre = [...this.ciclos];
      }
    },
  },

  methods: {
    handleColor(e) {
      this.color = e.hex;
    },

    adicionarRegra(item) {
      this.campoNovaRegra = item;
      this.novaRegra = true;
    },

    salvarRegraNocampo() {
      const campoNovaRegraId = this.campoNovaRegra;
      let novaRegra;

      if (!this.tipo) {
        this.$toast.danger("Preencha os campos");
        return;
      }

      if (this.tipo === "resultado") {
        novaRegra = {
          elem: this.elemento,
          tipo: this.tipo,
          campo_condicao: this.campo_condicao,
          condicao: this.condicao,
          resposta: this.resposta,
        };
      }

      if (this.tipo === "condicao") {
        novaRegra = {
          elem: this.variavelSelecionada,
          tipo: this.tipo,
          variavel: this.variavel,
          condicao: this.condicao,
          entao: this.entao,
        };
      }

      if (this.tipo === "campo") {
        novaRegra = {
          bimestre: this.cicloEscolhido.slug,
          elem: this.campoDoCicloEscolhido,
          tipo: this.tipo,
        };
      }

      if (this.tipo !== "condicao" && this.tipo !== "resultado" && this.tipo !== "campo") {
        novaRegra = { elem: this.elemento, tipo: this.tipo };
      }

      this.regras[this.campoNovaRegra].regras.push(novaRegra);

      this.salvar();
    },

    async adicionar() {
      if (!this.tipo) {
        this.$toast.danger("Preencha os campos");
        return;
      }

      if (this.tipo === "resposta") {
        this.regras.push({
          elem: this.elemento,
          tipo: this.tipo,
          campo_condicao: this.campo_condicao,
          condicao: this.condicao,
          resposta: this.resposta,
          color: this.color,
        });

        this.salvar();
        return;
      }

      if (this.tipo === "condicao") {
        this.regras.push({
          elem: this.variavelSelecionada,
          tipo: this.tipo,
          variavel: this.variavel,
          condicao: this.condicao,
          entao: this.entao,
        });
        this.salvar();
        return;
      }

      if (this.tipo === "resultado bimestral") {
        this.regras.push({
          elem: this.cicloEscolhido.slug,
          tipo: this.tipo,
          campo: this.campoDoCicloEscolhido,
          regras: [],
        });
        this.salvar();
        return;
      }

      if (this.tipo === "resultado em bimestre") {
        this.regras.push({
          tipo: this.tipo,
          ciclo_escolhido: this.cicloEscolhido.slug,
          campo: this.campo_condicao,
          ciclo_resultado: this.cicloEscolhidoDoResultado.slug,
          campo_resultado: this.campoDoCicloEscolhidoEmBimestre,
        });
        this.salvar();
        return;
      }

      if (this.tipo === "Habilitar/desabilitar campo") {
        this.regras.push({
          tipo: this.tipo,
          ciclo_escolhido: this.cicloEscolhido.slug,
          campo_condicao: this.campo_condicao,
          ciclo_resultado: this.cicloEscolhidoDoResultado.slug,
          campo_resultado: this.campoDoCicloEscolhidoEmBimestre,
          desabilitado: this.desabilitado,
          condicao: this.condicao,
        });
        this.salvar();
        return;
      }

      const objeto = { elem: this.elemento, tipo: this.tipo };
      this.regras.push(objeto);
      this.salvar();
      this.$toast.success("Campo adicionado com sucesso!");
    },

    async carregarCampos() {
      const cicloId = this.$route.params.id;
      const data = await this.$services.cicloService.getCampos(cicloId);
      this.regras = [...data.regras];
      this.ciclo = data;
      this.camposOld = data.campos.map((campo) => campo);
      this.campos = data.campos.map((campo) => campo.slug);

      this.carregarCiclos(this.ciclo);
    },

    async salvar() {
      const cicloId = this.$route.params.id;
      await this.$services.cicloService.adicionarRegras(this.regras, cicloId);
      this.$toast.success("Regra salva com sucesso");
    },

    async carregarCiclos(ciclo) {
      const data = await this.$services.cicloService.ciclosDoCircuito(ciclo.circuito_id);
      this.ciclos = data.data;
    },

    async carregarCamposDoCicloEscolhido(ciclo) {
      const data = await this.$services.cicloService.getCampos(ciclo);
      this.camposDoCicloEscolhido = [...data.campos];
    },

    async carregarCamposDoCicloEscolhidoEmBimestre(ciclo) {
      const data = await this.$services.cicloService.getCampos(ciclo);
      this.camposDoCicloEscolhidoEmBimestre = [...data.campos];
    },

    paraCima($event, i) {
      if (i === 0) {
        return;
      }
      const irmao = $event.target.closest(".pai").previousSibling;
      irmao.classList.add("animate__animated", "animate__headShake");
      this.limparClasse(irmao);

      this.algumaCoisa = i - 1;
      const array = arrayMoveImmutable(this.regras, i, i - 1);
      this.regras = [...array];

      this.salvar();
    },

    paraBaixo($event, i) {
      // const elemt = document.getElementById(i);
      const irmao = $event.target.closest(".pai").nextSibling;

      irmao.classList.add("animate__animated", "animate__headShake");

      this.limparClasse(irmao);

      const result = i === this.regras.length - 1 ? i : i + 1;
      const array = arrayMoveImmutable(this.regras, i, result);
      this.regras = [...array];

      this.salvar();
    },

    limparClasse($event) {
      return setTimeout(function () {
        $event.classList.remove("animate__animated", "animate__headShake");
      }, 500);
    },

    deletarRegra(i) {
      this.$modals.danger({
        title: "Cuidado!",
        message:
          "A exclusão desse campo podera afetar as regras definidas, Tem certeza que deseja deletar esse campo?",
        confirmationCode: i,
        confirmationMessage: `Por favor, digite <strong>${i}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando campo");
              try {
                // como é o primeiro item sempre é zero, ele nao mostra pk 0 e falso
                // entao eu passo o index + 1 pra funcao e aqui eu removo o 1
                this.regras.splice(i - 1, 1);
                this.salvar();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
              this.$toast.success("Regra salva com sucesso");
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    deletarSubRegra(i, subRegra) {
      this.$modals.danger({
        title: "Cuidado!",
        message:
          "A exclusão desse campo podera afetar as regras definidas, Tem certeza que deseja deletar esse campo?",
        confirmationCode: subRegra,
        confirmationMessage: `Por favor, digite <strong>${subRegra}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando campo");
              try {
                // this.regras.splice(i, 1);

                this.regras[i].regras.splice(subRegra, 1);
                this.salvar();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
              this.$toast.success("Regra salva com sucesso");
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
      // this.regras.splice(i, 1);
    },

    editarRegra(item, i) {
      this.regraEditavel = item;
      this.indexRegraEditavel = i;
      this.openModalEditarRegra = true;
    },

    atualizarRegra(index) {
      this.regras[index] = this.regraEditavel;
      this.salvar();
    },
  },
};
</script>

<style></style>
