<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Circuito de notas</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarCircuito" class="mr-2">Novo Circuito</v-btn>
        </div>
      </v-col>

      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="listaDeCircuitos"
          :loading="carregandoCircuitos"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="circuito of items" :key="circuito.id">
                <td>{{ circuito.id }}</td>

                <td>
                  <v-btn text>
                    {{ circuito.descricao }}
                  </v-btn>
                </td>

                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="ml-1"
                        color="blue lighten-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          () =>
                            $router.push({
                              name: 'circuito.ciclo.novo',
                              params: { id: circuito.id },
                            })
                        "
                      >
                        <v-icon small>mdi-link </v-icon>
                      </v-btn>
                    </template>
                    <span>Vincular ciclos</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => editarCircuito(circuito.id)"
                        x-small
                        class="ml-1"
                        color="info lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar ciclo</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => deletarCircuito(circuito)"
                        x-small
                        class="ml-1"
                        color="error lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar ciclo</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="parseInt(page_number)"
          @changeActionResponse="listarCircuitos"
          :search="search"
        >
        </e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  mounted() {
    this.listarCircuitos(this.pageNumber);
  },
  data() {
    return {
      carregandoCircuitos: false,
      listaDeCircuitos: [],
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Nome", value: "descricao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  methods: {
    async listarCircuitos(pageNumber, query = null) {
      const response = await this.$services.circuitoService.index(pageNumber, query);
      this.listaDeCircuitos = [...response.data];
      this.paginate = response;
    },

    criarCircuito() {
      this.$router.push({ name: "circuito.novo" });
    },

    editarCircuito(circuitoId) {
      this.$router.push({ name: "circuito.edit", params: { id: circuitoId } });
    },

    async deletarCircuito(circuito) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esse ciclo?",
        confirmationCode: circuito.id,
        confirmationMessage: `Por favor, digite <strong>${circuito.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Ciclo");
              try {
                await this.$services.circuitoService.deletarCircuito(circuito.id);
                this.listarCircuitos(this.pageNumber);
                this.$toast.success("Circuito deletado com sucesso");
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
