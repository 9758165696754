<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'caixas' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Criando Caixa
        </h1>
      </v-col>
      <v-col cols="12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)">
            <v-row v-for="caixa of form.caixas" :key="form.caixas.indexOf(caixa)">
              <v-col class="pt-0 pb-0" cols="4">
                <e-label>Descrição</e-label>
                <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="caixa.descricao"
                    :error-messages="errors"
                    prepend-inner-icon="mdi-pencil-box-outline"
                    filled
                    dense
                    solo
                  />
                </ValidationProvider>
              </v-col>
              <v-col class="pt-0 pb-0" cols="4">
                <ValidationProvider
                  name="Responsável"
                  :rules="`required|verificarUserVinculado:${caixa.user_id},${usuarios_adicionados}`"
                  v-slot="{ errors }"
                >
                  <e-label>Responsável</e-label>
                  <v-select
                    v-model="caixa.user_id"
                    :return-object="false"
                    :error-messages="errors"
                    :items="usuarios"
                    :item-title="(usuario) => usuario.name"
                    :item-text="(usuario) => usuario.name"
                    :item-value="(usuario) => usuario.id"
                    @change="verificarUsuarioAdicionaNaLista"
                    label="Selecione uma opção"
                    prepend-inner-icon="mdi-account-outline"
                    filled
                    dense
                    solo
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col class="pt-4 pb-0" cols="2">
                <v-checkbox v-model="caixa.situacao" label="Ativo" value="1"></v-checkbox>
              </v-col>
              <v-col class="pt-0 pb-0" cols="2">
                <v-btn
                  v-if="form.caixas.length > 1"
                  class="mt-8"
                  color="error"
                  @click="
                    () => (
                      (form.caixas = form.caixas.filter((caixaObj) => caixaObj !== caixa)),
                      verificarUsuarioAdicionaNaLista()
                    )
                  "
                  small
                  ><v-icon small>fa-trash</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-btn color="primary" type="submit" block>Enviar Dados</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="secondary" @click="adicionarCaixa"> Adicionar </v-btn>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      usuarios: [],
      usuarios_adicionados: [],
      form: { caixas: [{}] },
      caixa: {},
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      try {
        this.$loaderService.open("Carregando dados");
        this.usuarios = await this.$services.caixasService.listarUsuariosParaCriarCaixa();
        // Object.values(response).forEach((user) => {
        //   console.log();
        //   this.usuarios.push({
        //     id: user.id,
        //     name: user.caixa != null ? user.name + " (" + user.caixa.descricao + ")" : user.name,
        //   });
        // });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async submitForm() {
      try {
        this.$loaderService.open("Carregando dados");
        const response = await this.$services.caixasService.criar(this.form.caixas);
        this.$toast.success("Dados salvos com sucesso");
        this.$router.push({ name: "caixas" });
      } catch (error) {
        this.$toast.warn(error.message);
      }
      this.$loaderService.close();
    },
    adicionarCaixa() {
      this.form.caixas.push({});
    },
    verificarUsuarioAdicionaNaLista() {
      this.usuarios_adicionados = [];
      // console.log("função");
      this.form.caixas.filter((caixaObj, index) => {
        // console.log("addd");
        // console.log(caixaObj.user_id);
        this.usuarios_adicionados.push(caixaObj.user_id);
        //
      });
      // this.form.caixas.filter((caixaObj) => {
      //   console.log("***");
      //   console.log(caixaObj);
      // });
      // console.log("**********");
      // console.log(this.usuarios_adicionados);
      // console.log("x");
    },
  },
};
</script>
