import Axios from "@/plugins/Axios";

export class MovimentacaoService {
  async getCaixas(user_id, caixa_id, date) {
    const response = await Axios().get(
      `movimentacoes/${user_id}/caixa/${caixa_id}/historico/${date}`
    );
    return response.data;
  }

  async getCaixasUser(user_id) {
    const response = await Axios().get(`movimentacoes/${user_id}/caixas`);
    return response.data;
  }

  async getMensalidades(caixa_id, date) {
    const response = await Axios().get(`movimentacoes/${caixa_id}/mensalidades/${date}`);
    return response.data;
  }

  async getCaixaSangria(caixa_id) {
    const response = await Axios().get(`movimentacoes/sangria/${caixa_id}/get-caixa`);
    return response.data;
  }
}

export default new MovimentacaoService();
