import Parcela from "@/Models/Parcela";

const state = () => ({
  matriculaSelecionada: null,
  parcelaSelecionada: null,
  parcelas: null,
});

const mutations = {
  setMatriculaSelecionada(state, matricula) {
    state.matriculaSelecionada = matricula;
  },
  setParcelaSelecionada(state, parcela) {
    state.parcelaSelecionada = parcela;
  },
};

const actions = {};
const getters = {
  matriculaSelecionada: (state) => state.matriculaSelecionada,
  parcelaSelecionada: (state) =>
    state.parcelaSelecionada ? new Parcela(state.parcelaSelecionada) : null,
  parcelas: (state) => (state.matriculaSelecionada ? state.matriculaSelecionada.parcelas : null),
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
