import { render, staticRenderFns } from "./QuantidadeProfessoresCard.vue?vue&type=template&id=700f2201&scoped=true&"
import script from "./QuantidadeProfessoresCard.vue?vue&type=script&lang=js&"
export * from "./QuantidadeProfessoresCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700f2201",
  null
  
)

export default component.exports