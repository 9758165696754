<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col cols="12" />
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex mb-2 justify-space-between">
            <h1 class="text-h5">Gestão de Caixas</h1>
            <v-text-field
              v-model="search"
              class="search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
          <div class="align-self-center mb-2">
            <!-- {{ usuario_atual.caixa }} -->
            <v-btn
              class="me-5"
              v-if="
                usuario_atual.roles.name == 'super-admin' || usuario_atual.roles.name == 'admin'
              "
              color="success"
              @click="createCaixaPage"
              >Criar Caixa</v-btn
            >
            <v-btn
              v-if="usuario_atual.meu_caixa == null"
              size="x-large"
              color="success"
              @click="dialog_meu_caixa = !dialog_meu_caixa"
              >Meu Caixa (criar)</v-btn
            >
            <v-chip
              v-else
              color="green"
              text-color="white"
              label
              style="height: 36px"
              class="ma-2 elevation-3"
            >
              <v-icon small> fa fa-shopping-cart </v-icon>
              <span class="ms-2">Você possui um caixa criado</span>
            </v-chip>
          </div>
          <v-data-table
            :headers="tableHeaders"
            :items="caixas"
            :items-per-page="5"
            :loading="loadingCaixas"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:item.situacao="{ item: caixa }">
              <v-switch
                v-model="caixa.situacao"
                :disabled="atualizandoCaixa"
                dense
                :false-value="0"
                :true-value="1"
                flat
                @change="(novaSituacao) => toggleSituacaoCaixa(novaSituacao, caixa)"
              >
                <template v-slot:label>
                  <v-progress-circular
                    v-if="caixaSelecionado === caixa && atualizandoCaixa"
                    indeterminate
                    color="primary"
                    :width="3"
                    size="24"
                    class="ml-2"
                  />
                </template>
              </v-switch>
            </template>
            <template v-slot:item.estaAberto="{ item: caixa }">
              <v-chip
                v-if="caixa.estaAberto"
                small
                label
                color="success"
                :class="caixa.animationClass"
              >
                ABERTO
              </v-chip>
              <v-chip
                v-if="caixa.estaFechado"
                small
                label
                :class="caixa.animationClass"
                color="warning"
              >
                FECHADO
              </v-chip>
              <v-chip v-if="!caixa.estaAberto && !caixa.estaFechado" small label>
                SEM MOVIMENTAÇÃO
              </v-chip>
            </template>
            <template v-slot:item.responsavel="{ item: caixa }">
              <v-chip v-if="caixa.user" small label :class="caixa.animationClass" color="success">
                {{ caixa.user.name }}
              </v-chip>
              <v-chip v-if="!caixa.user" small label> - - - </v-chip>
            </template>

            <template v-slot:item.actions="{ item: caixa }">
              <v-tooltip v-if="$can(['caixas.*', 'caixas.abrir'])" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!(caixa.situacao && !caixa.estaAberto && !caixa.estaFechado)"
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openAbrirCaixaModal(caixa)"
                  >
                    <v-icon small> fa fa-shopping-cart </v-icon>
                  </v-btn>
                </template>
                <span>Abrir caixa</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$can(['caixas.*', 'caixas.fechar'])"
                    :disabled="!(caixa.situacao && caixa.estaAberto && !caixa.estaFechado)"
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openFecharCaixaModal(caixa)"
                  >
                    <v-icon small> fa fa-cart-arrow-down </v-icon>
                  </v-btn>
                </template>
                <span>Fechar caixa</span> </v-tooltip
              ><v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="primary"
                    v-bind="attrs"
                    class="ml-1"
                    v-on="on"
                    @click="() => openHistoricoCaixaDrawer(caixa)"
                  >
                    <v-icon small> fa fa-history </v-icon>
                  </v-btn>
                </template>
                <span>Dados do caixa</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <e-abrir-caixa-dialog
      :on-close="closeAbrirCaixaModal"
      :open="abrirCaixaDialog.open"
      :caixa="abrirCaixaDialog.caixa"
      :valor-inicial="abrirCaixaDialog.valorInicial"
    />
    <e-fechar-caixa-dialog
      :on-close="closeFecharCaixaModal"
      :open="fecharCaixaDialog.open"
      :caixa="fecharCaixaDialog.caixa"
    />
    <e-historico-caixa v-model="historicoCaixaDrawer.open" :caixa="historicoCaixaDrawer.caixa" />
    <!-- dialogo meu caixa -->
    <v-dialog v-model="dialog_meu_caixa" scrollable width="auto">
      <!-- <template v-slot:activator="{ props }"> -->
      <!-- <v-btn color="primary" v-bind="props"> Open Dialog </v-btn> -->
      <!-- </template> -->
      <v-card>
        <v-card-title>CRIE SEU CAIXA</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px; width: 500px">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitForm)">
              <v-row>
                <v-col cols="8" class="mt-8">
                  <e-label>Descrição</e-label>
                  <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form_meu_caixa.descricao"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-pencil-box-outline"
                      filled
                      dense
                      solo
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="2" class="mt-12">
                  <v-checkbox
                    v-model="form_meu_caixa.situacao"
                    label="Ativo"
                    value="1"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider class="mt-15"></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  class="error"
                  @click="dialog_meu_caixa = false"
                >
                  Fechar
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" class="success" type="submit">
                  Confirmar
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";
import EAbrirCaixaDialog from "../../components/Caixas/EAbrirCaixaDialog.vue";
import EFecharCaixaDialog from "../../components/Caixas/EFecharCaixaDialog.vue";
import EHistoricoCaixa from "../../components/Caixas/EHistoricoCaixa.vue";

export default {
  name: "Caixas",
  components: { EAbrirCaixaDialog, EFecharCaixaDialog, EHistoricoCaixa },
  data() {
    return {
      loadingCaixas: true,
      search: "",
      tableHeaders: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "descricao" },
        { text: "Ativo?", value: "situacao", align: "center" },
        { text: "Responsável", value: "responsavel", align: "center" },
        {
          text: "Situação",
          value: "estaAberto",
          sortable: false,
          align: "center",
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      atualizandoCaixa: false,
      caixaSelecionado: null,
      fecharCaixaDialog: {
        open: false,
        caixa: null,
      },
      abrirCaixaDialog: {
        open: false,
        valorInicial: 0,
        caixa: null,
      },
      historicoCaixaDrawer: {
        open: false,
        caixa: null,
      },
      dialog_meu_caixa: false,
      form_meu_caixa: {},
      usuario_atual: {
        roles: {},
      },
    };
  },
  computed: {
    ...mapGetters("Caixas", ["caixas"]),
  },
  async mounted() {
    this.$loaderService.open("Carregando caixas");
    try {
      await this.loadCaixas();
      this.usuario_atual = await this.$services.authService.getCurrentUser();
      this.usuario_atual.roles = await this.usuario_atual.roles[0];
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  methods: {
    async toggleSituacaoCaixa(novaSituacao, caixa) {
      this.atualizandoCaixa = true;
      this.caixaSelecionado = caixa;
      try {
        const caixaAtualizado = await this.$services.caixasService.update({
          ...caixa,
          situacao: novaSituacao,
        });
        caixa.situacao = caixaAtualizado.situacao;
        this.$toast.success("Caixa atualizado com sucesso!");
      } catch (error) {
        this.$handleError(error);
        caixa.situacao = !novaSituacao;
      }
      this.caixaSelecionado = null;
      this.atualizandoCaixa = false;
    },
    async openAbrirCaixaModal(caixa) {
      this.abrirCaixaDialog = {
        open: true,
        valorInicial: 0,
        caixa,
      };
    },
    async closeAbrirCaixaModal() {
      this.abrirCaixaDialog = {
        open: false,
        valorInicial: 0,
        caixa: null,
      };
    },
    async openFecharCaixaModal(caixa) {
      this.fecharCaixaDialog = {
        open: true,
        caixa,
      };
    },
    async closeFecharCaixaModal() {
      this.fecharCaixaDialog = {
        open: false,
        caixa: null,
      };
    },
    async loadCaixas() {
      this.loadingCaixas = true;
      try {
        this.$loaderService.open("Carregando Caixas");
        await this.$services.caixasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingCaixas = false;
      this.$loaderService.close();
    },
    createCaixaPage() {
      this.$router.push({ name: "createCaixa" });
    },
    openHistoricoCaixaDrawer(caixa) {
      this.historicoCaixaDrawer = {
        caixa,
        open: true,
      };
    },
    async submitForm() {
      try {
        const response = await this.$services.caixasService.criarMeuCaixa(this.form_meu_caixa);
        this.$toast.success("Parabéns, seu caixa foi criado.");
        await this.$services.caixasService.syncAll();
        this.form_meu_caixa = {};
      } catch (error) {
        this.$toast.warn(error.message);
      }
      this.dialog_meu_caixa = false;
    },
  },
};
</script>
