import Axios from "@/plugins/Axios";
// import store from "@/store";

export class FormasDePagamentoService {
  async syncAll() {
    const response = await Axios().get("formas-de-pagamento");
    return response.data;

    // if (this.syncAll.formasDePagamento) return this.syncAll.formasDePagamento;
    // const formasDePagamento = response.data;
    // // store.commit("FormasDePagamento/setFormasDePagamento", formasDePagamento);
    // this.syncAll.formasDePagamento = formasDePagamento;
    // return formasDePagamento;
  }
}
export default new FormasDePagamentoService();
