const state = () => ({
  notas: [],
});

// manipula o estado
const mutations = {
  setarNotas(state, notas) {
    state.notas = notas;
  },
};

// chama a mutacao
const actions = {
  setNotas({ commit }, notas) {
    // setar as notas
    commit("setarNotas", notas);
  },
};

const getters = {
  notas: (state) => state.notas,
};

const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
