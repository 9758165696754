import Role from "./Role";
import Franquia from "./Franquia";

export default class User {
  constructor(user) {
    Object.assign(this, user);
    this.roles = (this.roles || []).map((role) => new Role(role));
    if (this.franquia) this.franquia = new Franquia(this.franquia);
    this.roleName = this.roles.length > 0 ? this.roles[0].name : "";
  }

  hasPermission(permissionBeingTested) {
    if (!this.permissions) return false;
    return this.permissions.indexOf(permissionBeingTested) !== -1;
  }

  can(permission) {
    return this.permissions.includes(permission);
  }

  get role() {
    if (!this.roles || this.roles.length === 0) return null;
    return this.roles[0];
  }
}
