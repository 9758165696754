<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Novo Circuito de notas
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-4">
          <template>
            <template>
              <v-form @submit="handleFormSubmit">
                <v-text-field
                  v-model="form.descricao"
                  label="Ex: circuito ensino fundamental"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  required
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-btn :disabled="!form.descricao" color="success" type="submit">
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  mounted() {},

  data() {
    return {
      form: {
        descricao: "",
      },
    };
  },

  methods: {
    async handleFormSubmit(e) {
      e.preventDefault();
      this.salvarCircuito();
    },

    async salvarCircuito() {
      await this.$services.circuitoService.salvar(this.form);
      this.$router.go(-1);
      this.$toast.success("Circuito criado com sucesso !");
    },
  },
};
</script>

<style></style>
