<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }}&nbsp;Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Aluno
                  <v-icon>fa-graduation-cap</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Saúde
                  <v-icon>fa-solid fa-notes-medical</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                  Endereço do Aluno
                  <v-icon>fa-address-card</v-icon>
                </v-tab>

                <v-tab href="#tab-4">
                  Responsável Financeiro
                  <v-icon>mdi-account-box</v-icon>
                </v-tab>
                <v-tab href="#tab-5">
                  Endereço do Responsável
                  <v-icon>fa-address-card</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-1'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <template>
                            <!--UPLOAD-->
                            <form
                              enctype="multipart/form-data"
                              novalidate
                              v-if="isInitial || isSaving"
                            >
                              <h4>Carregar imagem</h4>
                              <div class="dropbox">
                                <input
                                  type="file"
                                  multiple
                                  :name="uploadFieldName"
                                  @change="
                                    filesChange($event.target.files);
                                    fileCount = $event.target.files.length;
                                  "
                                  accept="image/*"
                                  class="input-file"
                                />
                                <p v-if="isInitial">
                                  Arraste uma imagem aqui para começar<br />
                                  ou clique para navegar
                                </p>
                                <v-col class="text-center" v-if="isSaving">
                                  <img class="text-center" width="150x" :src="base64Img" />
                                  <p>Imagem carregada!</p>
                                </v-col>
                              </div>
                            </form>
                          </template>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="8">
                          <ValidationProvider
                            name="Nome Completo do Aluno"
                            ref="nomecompleto"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome Completo</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="form.nomecompleto"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opicional)"
                            v-model="form.nome_social"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="Genero"
                            ref="genero"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Gênero</e-label>
                            <e-autocomplete
                              :items="$constants.sexoList"
                              :return-object="false"
                              :error-messages="errors"
                              :item-value="(value) => value.id"
                              :item-text="(value) => value.descricao"
                              v-model="form.sexo_id"
                              label="Selecione uma opção"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Cor/Raça</e-label>
                          <e-autocomplete
                            :items="$constants.corOuRacaList"
                            :return-object="false"
                            v-model="form.cor_ou_raca"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Nome do Pai</e-label>
                          <v-text-field v-model="form.filiacao1" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <ValidationProvider
                            name="Nome da Mãe do Aluno"
                            ref="filiacao2"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome da Mãe</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="form.filiacao2"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="Data de Nascimento do Aluno"
                            ref="dataNascimento"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Data de nascimento</e-label>
                            <e-date-picker
                              :error-messages="errors"
                              v-model="form.data_nascimento"
                              placeholder="dd/mm/yyyy"
                              dense
                              solo
                            ></e-date-picker>
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="form.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>País de Nascimento</e-label>
                          <v-text-field v-model="form.naturalidade" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nacionalidade</e-label>
                          <e-autocomplete
                            :items="$constants.nacionalidadeList"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.nacionalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF de nascimento</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="form.estadualidade"
                            @change="(event) => atualizarEstado(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Município de nascimento</e-label>
                          <e-autocomplete
                            :items="municipiosNasc"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="form.municipalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Número do CPF</e-label>
                          <v-text-field v-mask="'###.###.###-##'" v-model="form.cpf" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>RG</e-label>
                          <v-text-field v-model="form.registrogeral" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.rgestado_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Data de Expedição</e-label>
                          <e-date-picker
                            v-model="form.rg_dataemissao"
                            placeholder="dd/mm/aaaa"
                          ></e-date-picker>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Celular</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.celular"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <!-- <ValidationProvider
                            name="Email do Aluno"
                            ref="emailAluno"
                            rules="required"
                            v-slot="{ errors }"
                          > -->
                          <!-- :error-messages="errors" -->
                          <e-label>Email</e-label>
                          <v-text-field
                            placeholder="email@email.com"
                            v-model="form.email"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Contato para emergencias</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.telefone1"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Obervações Emergência</e-label>
                          <v-text-field v-model="form.observacoes_emergencia" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Obervações Pessoas</e-label>
                          <v-text-field v-model="form.observacoes_pessoais" dense solo />
                        </v-col>
                        <v-col cols="12">
                          <v-btn color="primary" @click="changeTab(2)" block>Proximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>
                            DEFICIÊNCIA/TRANSTORNOS GLOBAIS DE DESENVOLVIMENTO/ALTAS HABILIDADES
                          </h4>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="selected"
                            label="Cegueira"
                            value="Cegueira"
                            color="primary"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="selected"
                            label="Baixa visão"
                            value="Baixa visão"
                            color="primary"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="selected"
                            label="Surdez Severa"
                            value="Surdez Severa"
                            color="primary"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-0 pb-0">
                          <v-checkbox
                            v-model="selected"
                            label="Surdez Moderada"
                            value="Surdez Moderada"
                            color="primary"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="12">
                          <v-checkbox
                            v-model="selected"
                            label="Síndrome de Down"
                            value="Síndrome de Down"
                            color="primary"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="has_autismo"
                            label="Autismo"
                            :value="has_autismo"
                            color="primary"
                            @change="openTextFieldAutismo"
                            class="pe-1"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-2 pb-1 p-4" cols="9">
                          <v-text-field
                            :disabled="!has_autismo"
                            variant="solo"
                            label="Descrição"
                            ref="myTextField"
                            v-model="textFieldValueAutismo"
                            hide-details
                            dense
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                          ><v-divider :thickness="2" class="border-opacity-100"></v-divider
                        ></v-col>
                        <!-- /Audismo -->

                        <!-- Deficência Mental -->
                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="has_defMental"
                            label="Deficiência Mental"
                            :value="has_defMental"
                            color="primary"
                            @change="openTextFieldDefMent"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-2 pb-1" cols="9">
                          <v-text-field
                            :disabled="!has_defMental"
                            label="Descrição"
                            v-model="textFieldValueDefMent"
                            hide-details
                            dense
                            solo
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12"
                          ><v-divider :thickness="2" class="border-opacity-100"></v-divider
                        ></v-col>

                        <!-- /Deficência Mental -->
                        <!-- Deficência Física -->

                        <v-col class="pt-0 pb-0" cols="3">
                          <v-checkbox
                            v-model="has_defFisica"
                            label="Deficiência Física"
                            :value="has_defFisica"
                            color="primary"
                            @change="openTextFieldDefFis"
                          ></v-checkbox>
                        </v-col>

                        <v-col class="pt-2 pb-1" cols="9">
                          <v-text-field
                            label="Descrição"
                            :disabled="!has_defFisica"
                            v-model="textFieldValueDefFis"
                            dense
                            solo
                          ></v-text-field>
                        </v-col>
                        <!-- /Deficência Física -->
                      </v-row>
                      <!-- DADOS SOBRE A SAÚDE -->
                      <v-row>
                        <v-col class="pt-0 pb-0" cols="12">
                          <h4>DADOS SOBRE A SAÚDE</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Tem alergia(s)?</e-label>
                          <v-combobox
                            v-model="selectedItemSaude"
                            :items="itemsAlergia"
                            label="Selecione uma opção"
                            dense
                            solo
                            @change="openTextFieldSaude"
                          >
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-subtitle v-if="itemsAlergia === 'Sim'" />
                              </v-list-item-content>
                            </v-col>
                          </v-combobox>
                        </v-col>
                        <v-col cols="8"></v-col>
                        <v-col cols="12" class="pt-0 pb-0" v-if="selectedItemSaude == 'Sim'">
                          <v-row>
                            <v-col cols="6">
                              <e-label>Detalhe?</e-label>
                              <v-text-field
                                label="Descrição"
                                v-model="textFieldValueDetalhe"
                                dense
                                solo
                              ></v-text-field>
                            </v-col>

                            <v-col cols="6">
                              <e-label>A medicamento?</e-label>
                              <v-text-field
                                label="Descrição"
                                v-model="textFieldValueMed"
                                dense
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <e-label>A alimentos?</e-label>
                              <v-text-field
                                label="Descrição"
                                v-model="textFieldValueAli"
                                dense
                                solo
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <e-label>Observações</e-label>
                              <v-text-field
                                label="Observações extras"
                                v-model="textFieldValueObsEx"
                                dense
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(1)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(3)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>

                      <!-- DADOS SOBRE A SAÚDE -->
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-3'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>DADOS SOBRE A ENDEREÇO DO(A) ALUNO(A)</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="CEP do Aluno"
                            ref="cepAluno"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>CEP</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="`##.###-###`"
                              v-model="form.cep"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="form.endereco" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Número</e-label>
                          <v-text-field v-mask="'#######'" v-model="form.numero" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="form.bairro" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="form.complemento" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="form.pais_residencia" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Estado </e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="form.uf_residencia"
                            @change="(event) => atualizarEstadoRes(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade </e-label>
                          <e-autocomplete
                            :items="municipiosRes"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="form.municipio_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Telefone Residencial</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="form.telefone2"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Zona de Residência </e-label>
                          <e-autocomplete
                            :items="$constants.zonaResidenciaList"
                            :return-object="false"
                            v-model="form.zona_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Localização diferenciada de residência</e-label>
                          <e-autocomplete
                            :items="$constants.localizacaoDiferenciadaDeResidenciaList"
                            :return-object="false"
                            v-model="form.zona_diferenciada_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(2)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(4)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-4'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>&nbsp;</e-label>
                          <v-btn @click="repetirDadosAluno()" color="light-green" block>
                            Repetir dados do Aluno
                          </v-btn>
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="12">
                          <ValidationProvider
                            name="Nome Completo do Responsavel"
                            ref="nomeResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome Completo do Responsavel</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="responsavel.descricao"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="8">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opicional)"
                            v-model="responsavel.nome_social"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Tipo Pessoa</e-label>
                          <e-autocomplete
                            :items="$constants.tipoPessoa"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.tipo_pessoa"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="CPF do Responsavel"
                            rules="required"
                            ref="cpfResponsavel"
                            v-slot="{ errors }"
                          >
                            <e-label>Número do CPF</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="'###.###.###-##'"
                              v-model="responsavel.cpf"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>RG</e-label>
                          <v-text-field v-model="responsavel.rg" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Orgão Expeditor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.estadoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="responsavel.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Nacionalidade</e-label>
                          <e-autocomplete
                            :items="$constants.nacionalidadeList"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="responsavel.naturalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF de nascimento</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="responsavel.estadualidade"
                            @change="(event) => atualizarEstado(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Município de nascimento</e-label>
                          <e-autocomplete
                            :items="municipiosNasc"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.municipalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="12">
                          <!-- <ValidationProvider
                            name="Email do Responsável"
                            ref="emailResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          > -->
                          <!-- :error-messages="errors" -->
                          <e-label>Email</e-label>
                          <v-text-field
                            placeholder="email@email.com"
                            v-model="responsavel.email"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(3)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(5)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-5'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>ENDEREÇO DO(A) RESPONSÁVEL DO(A) ALUNO(A)</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="2">
                          <ValidationProvider
                            name="CEP do Responsável"
                            ref="cepResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>CEP</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="`##.###-###`"
                              v-model="responsavel.cep"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="responsavel.logradouro" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Número</e-label>
                          <v-text-field
                            v-mask="'#######'"
                            v-model="responsavel.numero"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="responsavel.bairro" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="responsavel.complemento" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="responsavel.pais" dense solo disabled />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Estado </e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="responsavel.estado"
                            @change="(event) => atualizarEstadoRes(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                            disabled
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade </e-label>
                          <v-text-field v-model="responsavel.cidade" dense solo disabled />
                          <!-- <e-autocomplete
                            :items="municipiosRes"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.cidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          /> -->
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Telefone Residencial</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="responsavel.celular1"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Telefone de Trabalho</e-label>
                          <v-text-field
                            v-mask="'(##) ####-####'"
                            v-model="responsavel.celular2"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Ramal</e-label>
                          <v-text-field v-mask="'####'" v-model="responsavel.ramal" dense solo />
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(4)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            color="primary"
                            :disabled="submittingForm"
                            :loading="submittingForm"
                            type="submit"
                            @click="myFunctionToValidateFields()"
                            block
                            >Enviar Dados</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import { $toast } from "vuetify-snackbar-toast";
import { ref } from "vue";
import ELabel from "../../components/ELabel.vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        // if (formValue) {
        //   this.recursos_saeb = formValue.recursos_saeb;
        // } else {
        //   this.recursos_saeb = [];
        // }
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }
        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },
    "responsavel.cep": {
      handler(oldValue, newValue) {
        if (this.responsavel.cep.length === 10) {
          this.$loaderService.open("Consultando CEP");
          fetch(
            `https://brasilapi.com.br/api/cep/v2/${this.responsavel.cep
              .replace(".", "")
              .replace("-", "")}`
          )
            .then((response) => {
              if (response.status !== 200) {
                this.$toast.danger("O CEP informádo é inválido");
              }
              response.json().then((data) => {
                // console.log(data.neighborhood);
                this.responsavel.logradouro = data.street;
                this.responsavel.bairro = data.neighborhood;
                this.responsavel.estado = data.state;
                this.responsavel.cidade = data.city;
                this.responsavel.pais = "Brasil";
                // console.log(data)
              });
            })
            .finally(() => this.$loaderService.close());
          // let novocep = this.responsavel.cep.replace(".", "").replace("-", "");
        }
      },
      deep: true,
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    // ...mapGetters("Alunos", ["alunosList", "carregandoAlunos"]),
    // ...mapGetters("Alunos", ["alunos"]),
    ...mapGetters("Auth", ["user"]),
    checkboxLabel() {
      return this.selected ? "Opção selecionada" : "Selecione a opção";
    },
  },
  mounted() {
    this.reset();
    this.loadFormData();
  },

  data() {
    return {
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
      },
      responsavel: {
        descricao: " ",
      },
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      edit: true,
      imageAluno: null,
      countImagem: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      tab: "",
      podeEnviarFormulario: [],
      selected: [],
      has_autismo: false,
      has_defMental: false,
      has_defFisica: false,
      openDescriptionSelectionAutismo: false,
      openDescriptionSelectionDefMent: false,
      openDescriptionSelectionDefFis: false,
      textFieldValueDes: "",
      textFieldValueAutismo: "",
      textFieldValueDefMent: "",
      textFieldValueDefFis: "",
      textFieldValueDetalhe: "",
      textFieldValueMed: "",
      textFieldValueAli: "",
      textFieldValueObsEx: "",
      selectedItem: null,
      selectedItemSaude: null,
      itemsAlergia: ["Sim", "Não"],
      textFieldOpen: false,
      enabled: false,
    };
  },

  methods: {
    changeTab(tabindex) {
      this.tab = `tab-${tabindex}`;
    },

    myFunctionToValidateFields() {
      this.podeEnviarFormulario = [];
      this.validateField("cepResponsavel", 5);
      this.validateField("emailResponsavel", 3);
      this.validateField("cpfResponsavel", 4);
      this.validateField("nomeResponsavel", 4);
      this.validateField("cepAluno", 3);
      this.validateField("emailAluno", 1);
      this.validateField("dataNascimento", 1);
      this.validateField("filiacao2", 1);
      this.validateField("genero", 1);
      this.validateField("nomecompleto", 1);

      if (!this.podeEnviarFormulario.includes(false)) {
        this.submitForm();
      }
    },

    async validateField(field, tabindex) {
      const provider = this.$refs[field];

      provider
        .validate()
        .then((response) => {
          if (!response.valid) {
            this.$toast.danger(response.errors[0]);
            this.changeTab(tabindex);
          }
          this.podeEnviarFormulario.push(response.valid);
          // console.log(this.podeEnviarFormulario)
        })
        .catch((err) => {
          // console.log(err)
        });

      // console.log("Pode enviar o formulario? " + !this.podeEnviarFormulario.includes(false))
    },
    // ...mapActions("Alunos", ["loadAlunos"]),
    atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },
    atualizarEstadoRes(value) {
      if (value) {
        this.municipiosRes = this.$constants.municipios[value].cidades;
      }
    },
    // repetirDadosAluno(){
    //   this.form.nome_completo_responsavel = this.form.nomecompleto;
    //   console.log("Nome completo: " + this.form.nomecompleto);
    //   console.log("Nome completo: " + this.form.nome_completo_responsavel);
    // },

    async getResponsavel(responsavel_id) {
      try {
        const aluno = await this.$services.alunosService.getResponsavel(responsavel_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;

      this.handleHealthlyFields();

      try {
        if (this.responsavel) {
          const response = await this.$services.alunosService.criaOuAtualizaResposavel(
            this.responsavel
          );
          this.form.responsavel_id = response;
        }
      } catch (error) {
        this.submittingForm = false;
        if (error.error.status) {
          this.$toast.danger(error.error.message);
          return;
        }
        this.$toast.danger("Os dados informados estão invalidos");
      }

      if (this.editing) {
        try {
          const response = await this.$services.alunosService.atualizarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno(a) Atualizado(a) com sucesso");
          this.$router.push({ name: "alunos" });
        } catch (error) {
          this.submittingForm = false;
          this.$toast.danger(error.error.message);
          return;
        }
      } else {
        try {
          const response = await this.$services.alunosService.criarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno criado com sucesso");
          this.$router.push({ name: "alunos" });
        } catch (error) {
          this.submittingForm = false;
          if (error.error.status) {
            this.$toast.danger(error.error.message);
            return;
          }
          this.$toast.danger("Os dados informados estão invalidos");
          return;
        }
      }
      this.submittingForm = false;
    },

    handleHealthlyFields() {
      this.form.tipos_de_transtornos = this.selected;
      if (this.has_autismo) {
        this.form.descricao_autismo = this.textFieldValueAutismo;
      } else {
        this.form.has_autismo = Boolean(this.has_autismo);
        this.form.descricao_autismo = " ";
      }
      this.form.has_autismo = this.has_autismo;
      if (this.has_defMental) {
        this.form.descricao_defMental = this.textFieldValueDefMent;
      } else {
        this.form.has_defMental = Boolean(this.has_defMental);
        this.form.descricao_defMental = " ";
      }
      this.form.has_defMental = this.has_defMental;
      if (this.has_defFisica) {
        this.form.descricao_defFisica = this.textFieldValueDefFis;
      } else {
        this.form.has_defFisica = Boolean(this.has_defFisica);
        this.form.descricao_defFisica = " ";
      }
      this.form.has_defFisica = this.has_defFisica;
      if (this.selectedItemSaude === "Sim") {
        this.form.saude_detalhe = this.textFieldValueDetalhe;
        this.form.saude_medicamento = this.textFieldValueMed;
        this.form.saude_alimentos = this.textFieldValueAli;
        this.form.saude_observacoes = this.textFieldValueObsEx;
      } else {
        this.form.saude_detalhe = " ";
        this.form.saude_medicamento = " ";
        this.form.saude_alimentos = " ";
        this.form.saude_observacoes = " ";
      }
      this.form.selectedItemSaude = this.selectedItemSaude;
    },

    async loadFormData() {
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
      }
      const responsavel = await this.getResponsavel(aluno.responsavel_id);
      this.atualizarEstadoRes(aluno.uf_residencia);
      this.atualizarEstado(aluno.estadualidade);
      if (aluno.imagem) {
        this.currentStatus = STATUS_SAVING;
      }
      this.recursos_saeb = aluno.recursos_saeb ?? [];
      this.form = {
        ...aluno,
      };
      this.responsavel = {
        ...responsavel[0],
      };
      this.responsavel.estadoemissor_id = parseInt(this.responsavel.estadoemissor_id, 10);

      if (this.editing) {
        this.selected = aluno.tipos_de_transtornos;
      } else {
        this.selected = [];
      }

      this.has_autismo = aluno.has_autismo;
      this.has_defMental = aluno.has_defMental;

      this.has_defFisica = aluno.has_defFisica;

      this.textFieldValueAutismo = aluno.descricao_autismo;
      this.textFieldValueDefMent = aluno.descricao_defMental;

      this.textFieldValueDefFis = aluno.descricao_defFisica;

      this.textFieldValueDetalhe = aluno.saude_detalhe;
      this.textFieldValueMed = aluno.saude_medicamento;

      this.textFieldValueAli = aluno.saude_alimentos;

      this.textFieldValueObsEx = aluno.saude_observacoes;

      if (this.selectedItemSaude === "Sim") {
        this.selectedItemSaude = aluno.selectedItemSaude;
      }
      this.selectedItemSaude = aluno.selectedItemSaude;
    },

    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage("alunos/upload-image", this.imageAluno, id);
      } catch (error) {
        this.$handleError(error);
      }
    },

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.imageAluno = fileList[x];
        return fileList[x];
      });

      this.currentStatus = STATUS_SAVING;
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
    openTextFieldAutismo() {
      if (this.has_autismo) {
        this.$refs.myTextField.focus();
      }
      this.openDescriptionSelectionAutismo = this.has_autismo;
      this.textFieldValueAutismo = "";
    },
    openTextFieldDefMent() {
      this.openDescriptionSelectionDefMent = this.has_defMental;
      this.textFieldValueDefMent = "";
    },
    openTextFieldDefFis() {
      this.openDescriptionSelectionDefFis = this.has_defFisica;
      this.textFieldValueDefFis = "";
    },
    openTextFieldSaude() {
      if (this.selectedItemSaude) {
        this.textFieldOpen = this.selectedItemSaude === "Sim";
      } else {
        this.textFieldValueDetalhe = "";
        this.textFieldValueMed = "";
        this.textFieldValueAli = "";
        this.textFieldValueObsEx = "";
      }
      //
    },
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.bottomButtons {
  overflow: auto;
}

.btn {
  float: right;
}

.options {
  margin-top: 20px;
  margin-left: 15px;
  font-size: large;
}

.texto {
  margin-left: 8px;
  margin-right: 10px;
  font-size: large;
  padding-bottom: 10px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #04aa6d;
}

.invalid {
  background-color: #ffdddd;
}

.tab {
  display: none;
}

.block {
  display: block;
}

.none {
  visibility: hidden;
}
</style>
