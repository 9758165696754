<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="criandoTurma"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Turma
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="criandoTurma">
        <v-row>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Código</e-label>
            <v-text-field v-model="form.codigo" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="9">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field :error-messages="errors" v-model="form.descricao" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Tipo de mediação didático-pedagógica</e-label>
            <e-autocomplete
              :items="$constants.tiposDeMediacaoDidaticoPedagogica"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.tipos_de_mediacao_didatico_pedagogica"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Local de funcionamento diferenciado da turma</e-label>

            <e-autocomplete
              :items="$constants.localDeFuncionamentoDiferenciadoDaTurma"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.local_de_funcionamento_diferenciado_da_turma"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Horário Inicial</e-label>
            <!-- <e-time-picker v-model="form.horario_inicial" /> -->
            <v-text-field v-model="form.horario_inicial" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Horário Final</e-label>
            <v-text-field v-model="form.horario_final" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dias da semana da turma</e-label>

            <e-autocomplete
              :items="$constants.diasDaSemana"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.dias_da_semana"
              label="Selecione uma opção"
              dense
              solo
              multiple
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Tipo de atendimento</e-label>

            <e-autocomplete
              :items="$constants.tiposDeAtendimento"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.tipo_de_atendimento"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Tipo de atividade complementar</e-label>

            <e-autocomplete
              :items="$constants.atividadesComplementaresList"
              :return-object="false"
              :item-value="(item) => item.codigo"
              :item-text="(item) => `${item.codigo} - ${item.descricao}`"
              v-model="form.tipo_de_atividade_complementar"
              label="Selecione uma opção"
              dense
              solo
              multiple
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Modalidade</e-label>

            <e-autocomplete
              :items="$constants.modalidades"
              :return-object="false"
              :item-value="(value) => value"
              v-model="form.modalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Turno" rules="required" v-slot="{ errors }">
              <e-label>Turno</e-label>
              <e-autocomplete
                :items="turnosList"
                :loading="carregandoTurnos"
                :item-text="(turno) => turno.descricao"
                :return-object="false"
                :item-value="(turno) => turno.id"
                :error-messages="errors"
                v-model="form.turno_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Série</e-label>
            <ValidationProvider name="Série" rules="required" v-slot="{ errors }">
              <e-autocomplete
                :items="seriesList.filter((serie) => serie.situacao)"
                :loading="carregandoSeries"
                :item-text="(serie) => serie.descricao"
                :return-object="false"
                :item-value="(serie) => serie.id"
                :error-messages="errors"
                v-model="form.serieIdList"
                label="Selecione uma opção"
                dense
                solo
                multiple
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="TiposDeEnsinos" rules="required" v-slot="{ errors }">
              <e-label>Tipo de Ensino</e-label>
              <e-autocomplete
                :items="tiposDeEnsinosList"
                :loading="carregandoTiposDeEnsinos"
                :item-text="(tipoDeEnsino) => tipoDeEnsino.descricao"
                :item-value="(tipoDeEnsino) => tipoDeEnsino.id"
                :error-messages="errors"
                @change="alterarSistemaBNCC"
                v-model="form.curso_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4" v-if="form.curso_id == 10">
            <ValidationProvider name="sistemaBNCC" rules="required" v-slot="{ errors }">
              <e-label>Sistema BNCC</e-label>
              <e-autocomplete
                :items="sistemaBNCCList.filter((sistema) => !sistema.parent_id)"
                :loading="carregandoSistemaBNCC"
                :item-text="(sistemaBNCC) => sistemaBNCC.descricao"
                :item-value="(sistemaBNCC) => sistemaBNCC.id"
                :error-messages="errors"
                v-model="form.sistema_bncc_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="criandoTurma"
              :loading="criandoTurma"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      form: {},
      seriesList: [],
      turnosList: [],
      sistemaBNCCList: [],
      tiposDeEnsinosList: [],
      criandoTurma: false,
      carregandoSeries: false,
      carregandoTurnos: false,
      carregandoTiposDeEnsinos: false,
      carregandoSistemaBNCC: false,
    };
  },
  methods: {
    alterarSistemaBNCC() {
      if (this.form.curso !== 10) this.form.sistema_bncc_id = null;
    },
    async submitForm() {
      try {
        if (this.editing) {
          await this.$services.turmasService.atualizarTurma(this.form);
          this.$toast.success("Turma Atualizada com sucesso");
        } else {
          await this.$services.turmasService.criarTurma(this.form);
          this.$toast.success("Turma criada com sucesso");
        }
        this.$router.push({ name: "turmas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.criandoTurma = false;
    },
    async loadData() {
      this.$loaderService.open("Carregando dados");
      try {
        if (this.editing) {
          const { turma_id } = this.$route.params;
          const payload = await this.$services.turmasService.visualizarTurma(turma_id);
          const series = await payload.turma.series.map((serie) => (serie.id ? serie.id : serie));
          this.form = { ...payload.turma, serieIdList: series };
        }
        this.tiposDeEnsinosList = await this.$services.tiposDeEnsinosService.syncAll();
        this.turnosList = await this.$services.turnosService.syncAll();
        this.seriesList = await this.$services.seriesService.syncAll();
        const response = await this.$services.sistemaBNCCService.listarTodos();
        this.sistemaBNCCList = response.sistema;
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
