import Axios from "@/plugins/Axios";
import Usuario from "@/Models/User";

export class UsuariosService {
  async syncAll() {
    const response = await Axios().get("users");
    const usuarios = response.data.map((usuario) => new Usuario(usuario));
    return usuarios;
  }

  async syncAllPaginate(pageNumber, query = "") {
    const response = await Axios().get(`users/paginate?page=${pageNumber}&query=${query}`);
    // const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
    return response;
  }

  async getUsuario(usuario_id) {
    const response = await Axios().get(`users/${usuario_id}`);
    const usuario = new Usuario(response.data);
    return usuario;
  }

  async criarUsuario(usuario) {
    const response = await Axios().post("users", usuario);
    const novaUsuario = response.data;
    return novaUsuario;
  }

  async atualizarUsuario(usuario) {
    const response = await Axios().put(`users/${usuario.id}`, usuario);
    const usuarioAtualizada = response.data;
    return usuarioAtualizada;
  }

  async deletarUsuario(usuario) {
    const response = await Axios().delete(`users/${usuario.id}`);
    const usuarioDeletada = response.data;
    return usuarioDeletada;
  }

  async recuperarUsuario(usuario) {
    return this.atualizarUsuario({ ...usuario, deleted_at: null });
  }
}
export default new UsuariosService();
