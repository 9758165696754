<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          <span>Editar Ciclo </span>
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-4">
          <template>
            <v-form>
              <v-text-field
                v-model="form.descricao"
                label="Bimestre 1..."
                :rules="[(v) => !!v || 'Campo obrigatório']"
                required
              >
              </v-text-field>

              <v-row>
                <v-col>
                  <v-btn small color="success" @click="atualizarCiclo"> Atualizar </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  mounted() {
    this.ciclo_id = this.$route.params.id;
    this.getCiclo(this.ciclo_id);
  },

  data() {
    return {
      ciclo_id: this.$route.params.id,
      form: {
        descricao: "",
      },
    };
  },

  methods: {
    async getCiclo() {
      const response = await this.$services.cicloService.getCiclo(this.ciclo_id);
      this.form.descricao = response.descricao;
    },

    async atualizarCiclo() {
      await this.$services.cicloService.atualizarCiclo(this.ciclo_id, this.form).then(() => {
        this.$router.go(-1);
        this.$toast.success("Ciclo atualizado com sucesso!");
      });
    },
  },
};
</script>

<style></style>
