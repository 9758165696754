const newTheme = {
  primary: "#3434dd",
  secondary: "#2c2cb4",
  rufous: "#3434dd",
  accent: "#E86413",
  error: "#f44336",
  warning: "#ff9800",
  info: "#03a9f4",
  success: "#3434dd",
  anchor: "red",
  white: "#fff",
  blue: "#3434dd",
};

export default newTheme;
