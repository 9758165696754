<template>
  <main-template>
    <v-alert
      v-if="countImagem"
      class="animate__animated animate__headShake"
      style="position: absolute; right: 15px; width: 420px; height: 50px"
      type="error"
      >Você só pode carregar somente uma <strong>IMAGEM</strong>.</v-alert
    >
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text :to="{ name: 'avisos.index' }">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aviso
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template>
          <!--UPLOAD-->
          <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
            <h4>Carregar imagem</h4>
            <div class="dropbox">
              <input
                type="file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept="image/*"
                class="input-file"
              />
              <p v-if="isInitial">
                Arraste uma imagem aqui para começar<br />
                ou clique para navegar
              </p>
              <v-col class="text-center" v-if="isSaving">
                <img class="text-center" width="150x" :src="base64Img" />
                <p>Imagem carregada!</p>
              </v-col>
            </div>
          </form>
        </template>
      </v-col>
      <v-col class="d-flex" cols="8">
        <v-select
          :items="selects"
          label="Selecione um tipo"
          v-model="form.selected"
          outlined
          :disabled="editing"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          :false-value="1"
          :true-value="0"
          v-model="status"
          :label="
            situacoes[form.status || status] ? situacoes[form.status || status].descricao : '- - -'
          "
          color="success"
          :value="status"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="2" v-if="form.selected">
        <v-checkbox
          v-if="form.selected !== 'Alunos' && !editing"
          v-model="selectAll"
          color="success"
          :label="form.selected ? 'Todos' : 'Tipo vazio'"
        ></v-checkbox>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <ValidationProvider :name="prop" v-slot="{ errors }">
              <v-autocomplete
                v-model="form.items"
                :disabled="isUpdating"
                :items="items"
                filled
                chips
                color="blue-grey lighten-2"
                :label="form.selected"
                item-text="descricao"
                item-value="id"
                multiple
                :error-messages="errors"
                persistent-hint
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar color="primary" left>
                      <span style="color: #fff">{{
                        data.item.descricao
                          ? data.item.descricao.substring(0, 1).toUpperCase()
                          : "- - -"
                      }}</span>
                    </v-avatar>
                    {{ data.item.descricao }}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template right>
                    <v-avatar color="primary">
                      <span style="color: #fff">{{
                        data.item.descricao
                          ? data.item.descricao.substring(0, 3).toUpperCase()
                          : "- - -"
                      }}</span>
                    </v-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.descricao"></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.turno ? data.item.turno.descricao : '- - -'"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <ValidationProvider name="título" rules="required" v-slot="{ errors }">
              <v-text-field
                counter="25"
                hint="Você só pode digitar até 25 caracteres"
                label="Título"
                v-model="form.titulo"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="contexto" rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-1"
                label="Contexto da mensagem"
                v-model="form.contexto"
                hint="Hint text"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectAll: false,
      franquiaAtual: false,
      countImagem: false,
      autoUpdate: true,
      isUpdating: false,
      avisoUpload: null,
      // Mesmas variaveis usadas no controller se você mudar terá que colocar o mesmo nome
      selects: ["Turmas", "Escolas", "Alunos"],
      base64Img: "",
      name: "Midnight Crew",
      form: {
        titulo: "",
        contexto: "",
        items: [],
        selected: "",
      },
      submittingForm: false,
      items: [],
      situacoes: {
        0: {
          descricao: "Ativo",
          color: "blue lighten-1",
        },
        1: {
          descricao: "Inativo",
          color: "red lighten-1",
        },
        2: {
          descricao: "Enviado",
          color: "green lighten-1",
        },
      },
      title: "The summer breeze",
      prop: "turma",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      status: 0,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    selected() {
      return this.form.selected;
    },
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    selected(val) {
      this.selectAll = false;
      const functionDinamic = `load${val}`;
      if (!this.editing) {
        this.form.items = [];
      }
      // carrega de forma dinamica o autocomplete ['TURMAS', ALUNOS OU ESCOLAS]
      this[functionDinamic]();
      return "ok";
    },
    selectAll() {
      this.form.items = this.items.slice();
      if (!this.selectAll) {
        this.showOpcoes = true;
        this.form.items = [];
      }
    },
  },

  methods: {
    remove(item) {
      const index = this.form.items.indexOf(item.id);
      if (index >= 0) this.form.items.splice(index, 1);
    },

    async submitForm() {
      this.$loaderService.open("Salvando aviso aguarde...");
      try {
        this.form.status = this.form.status === 2 ? this.form.status : this.status;
        if (this.editing) {
          const response_imagem = await this.$services.imageService.uploadImage(
            "notifiq/avisos/upload-image",
            this.avisoUpload,
            parseInt(this.$route.params.aviso_id, 10)
          );
          this.form.imagem_alterada = response_imagem.data.imagem || null;
          await this.$services.avisosService.update(
            this.form,
            parseInt(this.$route.params.aviso_id, 10)
          );
          this.$toast.success("Aviso editado com sucesso!");
          this.$router.push({ name: "avisos.index" });
        } else {
          const response = await this.$services.avisosService.store(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aviso criado com sucesso!");
          this.$router.push({ name: "avisos.index" });
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.avisoUpload = fileList[x];
        return fileList[x];
      });

      this.currentStatus = STATUS_SAVING;
    },

    async loadTurmas() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.turmasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async loadEscolas() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.avisosService.franquias();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async loadAlunos() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.items = await this.$services.avisosService.alunosFranquia();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async loadData() {
      this.$loaderService.open("Carregando dados...");
      try {
        if (this.editing) {
          const response = await this.$services.avisosService.find(
            parseInt(this.$route.params.aviso_id, 10)
          );
          this.form = response;
          this.status = parseInt(response.status, 10);
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage(
          "notifiq/avisos/upload-image",
          this.avisoUpload,
          id
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  mounted() {
    this.reset();
    this.loadData();
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
