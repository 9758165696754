<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text class="text-h6 font-weight-bold">Taxa de evasão</v-card-text>

      <v-row>
        <v-col>
          <v-card-text class="text-h4 font-weight-bold"> {{ taxa ?? 0 }}% </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    franquiaId: {
      type: Number,
    },
  },
  name: "TaxaEvasao",
  mounted() {
    this.getTaxa(this.franquiaId);
  },
  watch: {
    franquiaId(value) {
      this.loading = true;
      this.getTaxa(value);
      this.loading = false;
    },
  },
  data() {
    return {
      loading: true,
      taxa: 0,
    };
  },
  methods: {
    async getTaxa(franquiaId) {
      const data = await this.$services.matriculasService.taxaEvasao(franquiaId);
      const taxa = data.toString();

      if (data) {
        this.taxa = taxa.substr(0, 3);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
