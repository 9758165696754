import MensalidadesPage from "../Pages/Mensalidades/index.vue";
import BaixarMensalidadePage from "../Pages/Mensalidades/BaixarMensalidadePage.vue";
import temCaixaAberto from "./guards/temCaixaAberto";

const mensalidades = [
  {
    path: "/mensalidades",
    name: "mensalidades",
    component: MensalidadesPage,
    beforeEnter: async (to, from, next) => {
      await temCaixaAberto(to, from, next);
      return next();
    },
  },
  {
    path: "/mensalidades/baixar/:prestacao_id/:matricula_id",
    name: "mensalidades.baixar",
    component: BaixarMensalidadePage,
  },
];

export default mensalidades;
