<template>
  <div class="pa-2">
    <v-card align="center" class="pa-3">
      <v-card-text>
        <span v-if="!user">Carregando...</span>
        <div v-if="user && !user.caixa" class="ml-2">
          <v-btn color="secondary" small :to="{ name: 'caixas' }"> ABRIR CAIXA </v-btn>
        </div>

        <div v-if="user && user.caixa" class="ml-2 text-uppercase">
          <div class="d-flex justify-space-around align-center">
            <div class="d-flex flex-column">
              <v-btn-toggle class="py-2" rounded>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      @click="() => openMovimentacao(user.caixa.id)"
                    >
                      <v-icon>mdi-bank-transfer</v-icon>
                    </v-btn>
                  </template>
                  <span>Movimentação do caixa</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      @click="() => openFecharCaixaDialog(user.caixa.id)"
                    >
                      <v-icon small> fa fa-cart-arrow-down </v-icon>
                    </v-btn>
                  </template>
                  <span>Fechar Caixa?</span>
                </v-tooltip>
              </v-btn-toggle>

              <v-chip label color="green lighten-4" class="d-flex justify-center" x-small>
                {{ user.caixa.descricao | truncate(9) }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <e-fechar-caixa-dialog
      :open="fecharCaixaDialog.open"
      :caixa="fecharCaixaDialog.caixa"
      :on-close="closeFecharCaixaDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EFecharCaixaDialog from "../../../components/Caixas/EFecharCaixaDialog.vue";
// import MainTemplateMenu from './MainTemplateMenu.vue';

export default {
  name: "ESituacaoCaixa",
  components: { EFecharCaixaDialog },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      fecharCaixaDialog: {
        open: false,
        caixa: null,
      },
    };
  },
  methods: {
    openFecharCaixaDialog() {
      this.fecharCaixaDialog = {
        caixa: this.user.caixa,
        open: true,
      };
    },
    closeFecharCaixaDialog() {
      this.fecharCaixaDialog = {
        caixa: null,
        open: false,
      };
    },
    openMovimentacao(caixa_id) {
      this.$router.push({
        name: "movimentacao",
      });
    },
  },
};
</script>
