import { $toast } from "vuetify-snackbar-toast";
import store from "@/store";
import authService from "../../Services/AuthService";

const temCaixaAberto = async (to, from, next) => {
  let currentUser = store.getters["Auth/user"];

  if (!currentUser) {
    await authService.syncUserWithStore();
  }
  currentUser = store.getters["Auth/user"];
  // console.log(currentUser);
  // if (!currentUser || !currentUser.caixa) {
  //   $toast.warn("Abra um caixa antes de acessar esta página");
  //   next({ name: "caixas" });
  // }
  // next({ name: "caixas" });
};

export default temCaixaAberto;
