<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text class="text-h6 font-weight-black"> Faixa etária </v-card-text>

      <apexchart height="100" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "FaixaEtariaCard",
  mounted() {
    this.getFaixaEtaria();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [100, 200, 300, 400],
      chartOptions: {
        chart: {
          height: 400,
          type: "donut",
          // offsetY: -25,
          offsetX: -20,
        },
        legend: {
          show: true,
          // horizontalAlign: "top",
          // position: "right",
          offsetY: -20,
          // offsetX: -20,
        },
        labels: ["0-6", "6-15", "15-18", "18+"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "bottom",
                offsetY: -80,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async getFaixaEtaria() {
      const data = await this.$services.alunosService.faixaEtaria();
      this.loading = false;
      this.series = [];
      // refatora isso pelo amor de deus
      this.series.push(parseInt(data.data[0].de0a6, 10));
      this.series.push(parseInt(data.data[0].de6a15, 10));
      this.series.push(parseInt(data.data[0].de15a18, 10));
      this.series.push(parseInt(data.data[0].mais18, 10));
    },
  },
};
</script>
