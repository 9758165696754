import Axios from "@/plugins/Axios";

export class AvisosService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(`notifiq/avisos?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async portalAluno(pageNumber, query) {
    const response = await Axios().get(`notifiq/avisos/portal?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async notificar(aviso_id) {
    const response = await Axios().get(`notifiq/avisos/notificar/${aviso_id}`);
    return response.data;
  }

  async destroy(aviso_id) {
    const response = await Axios().get(`notifiq/avisos/destroy/${aviso_id}`);
    return response.data;
  }

  async store(form) {
    const response = await Axios().post("notifiq/avisos/store", form);
    return response.data;
  }

  async update(form, aviso_id) {
    const response = await Axios().post(`notifiq/avisos/update/${aviso_id}`, form);
    return response.data;
  }

  async find($aviso_id) {
    const response = await Axios().get(`notifiq/avisos/new-edit/${$aviso_id}`);
    return response.data;
  }

  async franquias() {
    const response = await Axios().get(`notifiq/avisos/franquias`);
    return response.data;
  }

  async alunosFranquia() {
    const response = await Axios().get(`notifiq/avisos/alunos`);
    return response.data;
  }
}
export default new AvisosService();
