import Axios from "@/plugins/Axios";

export class AgendaService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(`notifiq/agendas?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async store(form) {
    const response = await Axios().post("notifiq/agendas/store", form);
    return response.data;
  }

  async update(form) {
    const response = await Axios().post(`notifiq/agendas/update`, form);
    return response.data;
  }

  async destroy(agenda_id) {
    const response = await Axios().get(`notifiq/agendas/destroy/${agenda_id}`);
    return response.data;
  }

  async find($agenda_id) {
    const response = await Axios().get(`notifiq/agendas/new-edit/${$agenda_id}`);
    return response.data;
  }

  async turmas() {
    const response = await Axios().get(`notifiq/agendas/turmas`);
    return response.data;
  }

  async notificar(agenda_id) {
    const response = await Axios().get(`notifiq/agendas/notificar/${agenda_id}`);
    return response.data;
  }
}
export default new AgendaService();
