<template>
  <v-col cols="12" md="3">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text class="text-h6 font-weight-bold"> Matriculas </v-card-text>

      <v-row>
        <v-col>
          <v-card-text class="text-h4 font-weight-bold">
            {{ qtd ?? 0 }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    franquiaId: {
      type: Number,
    },
  },
  name: "MatriculasPorUnidade",
  mounted() {
    this.getQtd(this.franquiaId);
  },
  watch: {
    franquiaId(value) {
      this.loading = true;
      this.getQtd(value);
      this.loading = false;
    },
  },
  data() {
    return {
      loading: true,
      qtd: 0,
    };
  },
  methods: {
    async getQtd(franquia) {
      this.qtd = await this.$services.matriculasService.qtdPorFranquia(franquia);
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
