<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ ciclo.descricao ?? ciclo.descricao }} / Novo Campo
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-4">
          <template>
            <v-form>
              <h2>Criar campo</h2>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.nomecampo"
                    label="Nome do campo"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    filled
                    v-model="form.notaminima"
                    label="Valor minimo da nota"
                    required
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    filled
                    v-model="form.notamaxima"
                    label="Valor maximo da nota"
                    required
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field filled v-model="form.media" label="média" required></v-text-field>
                </v-col>

                <v-col>
                  <v-checkbox
                    filled
                    v-model="form.editavel"
                    label="desabilitado"
                    required
                  ></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox filled v-model="form.label" label="label?" required></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox filled v-model="form.decimal" label="0,00" required></v-checkbox>
                </v-col>
              </v-row>

              <v-col>
                <v-btn :disabled="!form.nomecampo" color="success" @click="salvarCiclo">
                  Salvar
                </v-btn>
              </v-col>
            </v-form>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="mt-8 mb-5"></v-divider>

    <v-row>
      <v-col>
        <span class="text-h5">Campos</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table v-if="campos">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Campo</th>
                <th class="text-left">Nota minina</th>
                <th class="text-left">Nota maxima</th>
                <th class="text-left">Média</th>
                <th class="text-left">Desabilitado</th>
                <th class="text-left">Decimal</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <draggable
              tag="tbody"
              v-model="campos"
              group="people"
              @start="drag = true"
              @end="drag = false"
              @change="atualizarTodosOsCampos()"
            >
              <tr v-for="item in campos" :key="item.id" style="curso">
                <td>{{ item.id }}</td>
                <td>{{ item.nomecampo }}</td>
                <td>{{ item.notaminima }}</td>
                <td>{{ item.notamaxima }}</td>
                <td>{{ item.media }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="grey" dark v-bind="attrs" v-on="on">
                        {{ item.editavel ? "mdi-pencil-off" : "mdi-form-textbox" }}
                      </v-icon>
                    </template>
                    <span>{{ item.editavel ? "sim" : "não" }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="grey" dark v-bind="attrs" v-on="on">
                        {{
                          item.decimal ? "mdi-decimal-comma-increase" : "mdi-decimal-comma-decrease"
                        }}
                      </v-icon>
                    </template>
                    <span>{{ item.decimal ? "sim" : "não" }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="editarCampo(item)"
                        x-small
                        class="ml-1"
                        color="info lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar campo</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => deletarCampo(item)"
                        x-small
                        class="ml-1"
                        color="error lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar campo</span>
                  </v-tooltip>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              class="ml-1"
              color="blue lighten-1"
              v-bind="attrs"
              v-on="on"
              @click="vincularRegras(item)"
            >
              Regras
              <v-icon right small>mdi-link </v-icon>
            </v-btn>
          </template>
          <span>Vincular regras</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-divider class="mt-8 mb-5"></v-divider>

    <v-row>
      <v-col>
        <span class="text-h5 mt-5">Teste de regra / {{ ciclo.descricao }}</span>
      </v-col>
    </v-row>

    <div class="aluno mt-8">
      <v-card class="px-5">
        <v-form>
          <template>
            <v-row>
              <v-col v-for="campo in campos" :key="campo.id">
                <label :for="campo.id">{{ campo.nomecampo }}</label>

                <v-currency-field
                  v-if="!campo.label"
                  filled
                  v-model="campo.nota"
                  @input="resultado(campo, regras)"
                  :allow-negative="false"
                  :min="campo.nota_minima"
                  :max="campo.nota_maxima"
                  :decimal-length="campo.decimal ? 2 : 1"
                  ref="input"
                  v-bind="{ outlined: true }"
                  :disabled="campo.editavel"
                />

                <!-- :background-color="campo.color ?? campo.color" -->

                <v-text-field
                  v-if="campo.label"
                  :id="campo.id"
                  outlined
                  v-model="campo.nota"
                  :disabled="campo.editavel"
                  :background-color="campo.color ?? campo.color"
                >
                  {{ campo.nota }}
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card>
    </div>

    <v-dialog v-model="showDialogEdit" max-width="500" v-if="itemSelecionado.id">
      <v-card>
        <v-card-title class="text-h5">Editando campo </v-card-title>

        <v-card-text>
          <v-text-field
            :rules="[(v) => !!v || 'Campo obrigatório']"
            v-model="itemSelecionado.nomecampo"
            label="Novo nome"
            required
          ></v-text-field>

          <v-text-field
            v-model="itemSelecionado.notaminima"
            label="Nota minina"
            required
          ></v-text-field>

          <v-text-field
            v-model="itemSelecionado.notamaxima"
            label="Nota maxima"
            required
          ></v-text-field>

          <v-text-field v-model="itemSelecionado.media" label="Média" required></v-text-field>

          <v-checkbox
            v-model="itemSelecionado.editavel"
            :label="`Desabilitado: ${itemSelecionado.editavel ? 'sim' : 'não'}`"
          ></v-checkbox>

          <v-checkbox
            v-model="itemSelecionado.label"
            :label="`Label: ${itemSelecionado.label ? 'sim' : 'não'}`"
          ></v-checkbox>

          <v-checkbox
            v-model="itemSelecionado.decimal"
            :label="`Decimal: ${itemSelecionado.decimal ? 'sim' : 'não'}`"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialogEdit = false"> Cancelar </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="(showDialogEdit = false), salvarCampoAtualizado()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      form: {
        id: null,
        editavel: false,
        notamaxima: 0,
        notaminima: 0,
        nomecampo: "",
        media: 0,
        color: null,
        decimal: false,
      },
      regras: [],
      regra: [],
      showDialogEdit: false,
      ciclo_id: this.$route.params.id,
      campos: [],
      itemSelecionado: {},
      ciclo: {},
      campoAtualizado: {},
      enabled: true,
      dragging: false,
      color: null,
    };
  },

  created() {
    this.getCampos(this.$route.params.id);
  },

  methods: {
    async atualizarTodosOsCampos() {
      await this.$services.cicloService.adicionarCampo(this.campos, this.ciclo_id);
    },

    async salvarCiclo() {
      this.form.id = uuidv4();
      this.form.slug = this.string_to_slug(this.form.nomecampo);
      this.campos.push(this.form);
      await this.$services.cicloService
        .adicionarCampo(this.campos, this.ciclo_id)
        .then(() => {
          this.$toast.success("Campo salvo com sucesso!");
          this.getCampos(this.ciclo_id);
        })
        .catch(() => {
          this.$toast.danger("Ocorreu um problema ao salvar o campo, tente novamente");
        });
      this.campos = [];
    },

    async getCampos(cicloId) {
      const data = await this.$services.cicloService.getCampos(cicloId);
      this.ciclo = data;
      this.regras = [...data.regras];
      this.campos = [...data.campos];
    },

    async editarCampo(item) {
      this.itemSelecionado = { ...item };
      this.showDialogEdit = true;
    },

    vincularRegras() {
      const cicloId = this.$route.params.id;
      this.$router.push({
        name: "circuito.ciclo.campo.novaRegra",
        params: {
          id: cicloId,
        },
      });
    },

    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to = "aaaaeeeeiiiioooouuuunc------";

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "_") // collapse whitespace and replace by -
        .replace(/-+/g, "_"); // collapse dashes

      return str;
    },

    async deletarCampo(campo) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "A exclusão desse campo afetará a regras definidas!",
        confirmationCode: campo.id,
        confirmationMessage: `Digite <strong>${campo.id}</strong> para continuar.`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar exclusão",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Campo");
              try {
                const novosCampos = this.campos.filter((c) => c.id !== campo.id);
                await this.$services.cicloService
                  .adicionarCampo(novosCampos, this.ciclo_id)
                  .then(() => {
                    this.$toast.success("Campo deletado com sucesso!");
                    this.getCampos(this.ciclo_id);
                  })
                  .catch(() => {
                    this.$toast.danger("Ocorreu um problema ao deletar o campo, tente novamente");
                  });
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    async salvarCampoAtualizado() {
      this.$modals.danger({
        title: "Cuidado!",
        message:
          "A alteração desse campo podera afetar as regras definidas, Tem certeza que deseja alterar esse campo?",
        confirmationCode: this.itemSelecionado.id,
        confirmationMessage: `Por favor, digite <strong>${this.itemSelecionado.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Atualizando campo");
              try {
                const indexDoCampo = this.campos.findIndex(
                  (campo) => campo.id === this.itemSelecionado.id
                );
                this.campos[indexDoCampo] = this.itemSelecionado;
                await this.$services.cicloService
                  .adicionarCampo(this.campos, this.ciclo_id)
                  .then(() => {
                    this.$toast.success("Campo atualizado com sucesso!");
                    this.getCampos(this.ciclo_id);
                  })
                  .catch(() => {
                    this.$toast.danger("Ocorreu um problema ao atualizar o campo, tente novamente");
                  });
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    resultado(campo, regras) {
      if (campo.nota > campo.notamaxima) {
        campo.nota = campo.notamaxima;
      }

      let expressao = "";

      regras.map((r) => {
        if (
          r.tipo === "conjunto" ||
          r.tipo === "expressao" ||
          r.tipo === "campo" ||
          r.tipo === "numero"
        ) {
          expressao += r.elem;
        }
        return expressao;
      });

      regras.map((r) => {
        if (r.tipo === "campo") {
          const valorElemento = this.campos.filter((campo) => campo.slug === r.elem)[0].nota;
          return (expressao = expressao.toString().replaceAll(r.elem, valorElemento ?? 0));
        }
        if (r.tipo === "resultado") {
          this.campos.map((campo) => {
            if (campo.slug === r.elem) {
              campo.nota = eval(expressao);
            }
          });
        }
        if (r.tipo === "resposta") {
          const campoCondicao = r.campo_condicao;
          const campoValor = this.campos.filter((campo) => campo.slug === campoCondicao)[0].nota;
          const resultadoExpresao = r.condicao.toString().replaceAll(campoCondicao, campoValor);
          this.campos.map((campo) => {
            if (campo.slug === r.elem) {
              if (eval(resultadoExpresao)) {
                campo.nota = r.resposta;
                campo.color = r.color;
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.not-draggable {
  cursor: no-drop;
}
</style>
