import Axios from "@/plugins/Axios";

export class CicloService {
  async salvar(form) {
    const response = await Axios().post("ciclos/salvar", { form });
    return response.data;
  }

  async ciclosDoCircuito(circuitoId) {
    const response = await Axios().get(`ciclos/listar-ciclos-circuito/${circuitoId}`);
    return response;
  }

  async adicionarCampo(form, cicloId) {
    const response = await Axios().post(`ciclos/campo/adicionar/${cicloId}`, { form });
    return response.data;
  }

  async getCampos(cicloId) {
    const response = await Axios().get(`ciclos/campos/${cicloId}`);
    return response.data;
  }

  async deletarCiclo(cicloId) {
    const response = await Axios().delete(`ciclos/${cicloId}`);
    return response.data;
  }

  async getCiclo(cicloId) {
    const response = await Axios().get(`ciclos/${cicloId}`);
    return response.data;
  }

  async atualizarCiclo(cicloId, form) {
    const response = await Axios().put(`ciclos/${cicloId}`, { form });
    return response.data;
  }

  async adicionarRegras(novasRegras, ciclo) {
    const response = await Axios().post(`ciclos/${ciclo}/regras`, { novasRegras });
    return response.data;
  }

  // Ciclos
  async getCiclos() {
    const response = await Axios().get("ciclos");
    return response;
  }
}

export default new CicloService();
