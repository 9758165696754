<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row>
          <v-col>
            <iframe :src="pdf" frameborder="0" style="min-height: 100vh; width: 100%"> </iframe>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
    dialog: {
      type: Boolean,
    },
    pdf: {
      value: File,
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.$emit("CloserModalRecibo", false);
    },
  },
};
</script>
